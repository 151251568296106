import React from 'react';

function getMessage(subscription) {
  if (subscription.status === 'canceled') {
    return 'Your subscription is canceled.';
  }

  const endDate = new Date(subscription.currentPeriodEnd * 1000);
  const readableDate = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
  }).format(endDate);

  return subscription.cancelAtPeriodEnd
    ? `Your plan will be canceled on ${readableDate}.`
    : `Your plan renews on ${readableDate}.`;
}

export default function SubscriptionInfo({subscription, plan}) {
  return (
    <div className="space-y-2 font-mono">
      <h2 className="text-xl font-semibold">{plan}</h2>
      <p>{getMessage(subscription)}</p>
    </div>
  );
}

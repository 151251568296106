import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {cloneProject} from '../actions/projects';
import {showForm} from '../actions/forms';
import {DONATE} from '../reducers/forms';
import React from 'react';
import Splash from './Splash';

class CloneProjectHandler extends React.Component {
  componentDidMount() {
    const {
      match: {params},
      history,
    } = this.props;

    this.props.cloneProject({id: params.id}, (error, id) => {
      if (error) {
        return error.status === 402
          ? this.props.showForm(DONATE, {id: params.id})
          : alert(error.message);
      }
      history.push(`/projects/${id}`);
    });
  }

  render() {
    return (
      <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
        <Splash text="Cloning project..." />
      </div>
    );
  }
}

export default connect(null, dispatch =>
  bindActionCreators({cloneProject, showForm}, dispatch)
)(CloneProjectHandler);

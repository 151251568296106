import {DONATE, EMAIL_UPDATE} from '../reducers/forms';
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import Dropdown from './Dropdown';
import Logo from './Landing/Logo';
import {NavLink} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {logout} from '../actions/user';
import {showForm} from '../actions/forms';

const UserDropdown = ({
  user,
  logout,
  showSubscriptionModal,
  showUpdateEmailModal,
}) => (
  <Dropdown iconName="User" tooltip="User menu">
    <Dropdown.MenuTitle>Hi, {user.name}!</Dropdown.MenuTitle>
    <Dropdown.Item index={0} iconName="Mail" onClick={showUpdateEmailModal}>
      Update email
    </Dropdown.Item>
    <Dropdown.Item
      index={1}
      iconName="CreditCard"
      onClick={showSubscriptionModal}>
      Manage subscription
    </Dropdown.Item>
    <Dropdown.Item index={2} iconName="LogOut" onClick={logout}>
      Logout
    </Dropdown.Item>
  </Dropdown>
);

class Appbar extends Component {
  render() {
    const {user} = this.props;

    return (
      <div className="max-w-6xl mx-auto px-3 sm:px-4 py-2 flex align-middle justify-between">
        <NavLink to="/">
          <Logo />
        </NavLink>
        <div className="flex items-center gap-3 font-mono">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/mockapi-io/docs/wiki"
            className="text-md font-semibold rounded-xl px-3 py-2 box-border transition hover:bg-gray-200 active:scale-95">
            Docs
          </a>
          {user.authorized ? (
            <UserDropdown
              user={user}
              logout={this.props.logout}
              showUpdateEmailModal={() => this.props.showForm(EMAIL_UPDATE)}
              showSubscriptionModal={() => this.props.showForm(DONATE)}
            />
          ) : (
            <NavLink
              to="/login"
              className="text-md font-semibold rounded-xl px-3 py-1 box-border border-2 border-gray-800 transition hover:bg-gray-200 active:scale-95">
              Login
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

const ConnectedAppBar = connect(
  ({user}) => ({user}),
  dispatch => bindActionCreators({logout, showForm}, dispatch)
)(Appbar);

export default function () {
  return (
    <Switch>
      <Route exact path="/" component={ConnectedAppBar} />
      <Route exact path="/projects" component={ConnectedAppBar} />
      <Route exact path="/projects/:id" component={ConnectedAppBar} />
    </Switch>
  );
}

import {
  CLONE_PROJECT,
  COLLABORATORS,
  DELETE_PROJECT,
  EDIT_PROJECT,
  MAGIC_LINK,
} from '../reducers/forms';

import Dropdown from './Dropdown';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showForm} from '../actions/forms';

const ProjectActions = ({showForm, project, orient, variant = 'secondary'}) => (
  <Dropdown
    iconName="MoreVertical"
    orient={orient}
    variant={variant}
    tooltip="Project actions">
    <Dropdown.MenuTitle>Actions</Dropdown.MenuTitle>
    <Dropdown.Item
      index={0}
      iconName="Users"
      iconStyle={{className: 'text-blue-600'}}
      onClick={event => showForm(COLLABORATORS, project)}>
      Collaborators
    </Dropdown.Item>
    <Dropdown.Item
      index={1}
      iconName="Link"
      iconStyle={{className: 'text-blue-600'}}
      onClick={event => showForm(MAGIC_LINK, project)}>
      Magic link
    </Dropdown.Item>
    <Dropdown.Separator />
    <Dropdown.Item
      index={2}
      iconName="Copy"
      onClick={event => showForm(CLONE_PROJECT, project)}>
      Clone
    </Dropdown.Item>
    <Dropdown.Item
      index={3}
      iconName="Edit2"
      onClick={event => showForm(EDIT_PROJECT, project)}>
      Edit
    </Dropdown.Item>
    <Dropdown.Separator />
    <Dropdown.Item
      index={4}
      iconName="Trash2"
      iconStyle={{className: 'text-rose-500'}}
      onClick={event => showForm(DELETE_PROJECT, project, undefined, true)}>
      Delete
    </Dropdown.Item>
  </Dropdown>
);

export default connect(null, dispatch =>
  bindActionCreators({showForm}, dispatch)
)(ProjectActions);

import Icon from '../Icon';
import React from 'react';
import Spinner from '../Spinner';

const Notification = ({data: n}) => (
  <div className="flex items-center px-3 py-2 rounded-lg bg-gray-900 text-white text-sm font-medium font-mono shadow">
    <span style={{marginRight: 10}}>
      {n.type === 'progress' && <Spinner />}
      {n.type === 'success' && (
        <Icon name="Check" className="stroke-emerald-500" />
      )}
      {n.type === 'failure' && (
        <Icon name="AlertTriangle" className="stroke-rose-600" />
      )}
    </span>
    {n.message}
  </div>
);

export default Notification;

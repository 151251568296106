import {
  CloneProjectModal,
  DeleteProjectModal,
  EditProjectModal,
  NewProjectModal,
} from './ProjectForm';
import {
  DeleteResourceModal,
  EditResourceModal,
  NewResourceModal,
  ResourceDataModal,
} from './ResourceForm';
import React, {Fragment} from 'react';

import CollaboratorsModal from './CollaboratorsModal';
import MagicLinkModal from './MagicLinkModal';
import SubscriptionModal from './SubscriptionModal';
import UpdateEmailModal from './UpdateEmailModal';

const ModalLayer = () => (
  <Fragment>
    <SubscriptionModal />
    <NewProjectModal />
    <EditProjectModal />
    <CloneProjectModal />
    <DeleteProjectModal />
    <NewResourceModal />
    <EditResourceModal />
    <DeleteResourceModal />
    <ResourceDataModal />
    <MagicLinkModal />
    <CollaboratorsModal />
    <UpdateEmailModal />
  </Fragment>
);

export default ModalLayer;

import {getToken} from '../actions/utils';

export function list(projectId) {
  const url = `/api/mocks/${projectId}/collaborators`;
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {headers}).then(res => res.json());
}

export function add({email, projectId: mockId}) {
  const url = '/api/collaborators';
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({email, mockId}),
  }).then(res => res.json());
}

export function remove(collaboratorId) {
  const url = `/api/collaborators/${collaboratorId}`;
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {method: 'DELETE', headers}).then(res => res.json());
}

export function accept(collaboratorId) {
  const url = `/api/collaborators/${collaboratorId}/accept`;
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {
    method: 'PUT',
    headers,
  }).then(res => res.json());
}

import React from 'react';
import Tag from './Tag';

function statusToVarinat(status) {
  switch (status) {
    case 'pending':
      return 'default';
    case 'accepted':
      return 'success';
    case 'rejected':
      return 'danger';
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}

export default function InviteStatus({status}) {
  return <Tag variant={statusToVarinat(status)} label={status} />;
}

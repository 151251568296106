import {NEW_RESOURCE, formStatus} from '../../reducers/forms';
import React, {Component} from 'react';
import {clearFormError, hideForm} from '../../actions/forms';

import Modal from '../Modal';
import ResourceForm from './ResourceForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createResource} from '../../actions/resources';
import {get} from 'lodash';
import {hasActiveSub} from '../../utils/user';

class NewResourceModal extends Component {
  render() {
    const {form, isActiveSub, isCollaborator} = this.props;

    return (
      <Modal
        size="xl"
        show={form.show}
        onHide={this.onHide}
        title="New resource">
        <ResourceForm
          form={form}
          onHide={this.onHide}
          onSubmit={this.props.createResource}
          clearFormError={this.clearFormError}
          isActiveSub={isActiveSub || isCollaborator}
        />
      </Modal>
    );
  }

  clearFormError = () => {
    this.props.clearFormError(NEW_RESOURCE);
  };

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(NEW_RESOURCE);
    }
  };
}

export default connect(
  ({forms, user}) => {
    const form = formStatus(forms, NEW_RESOURCE);
    return {
      form,
      isActiveSub: hasActiveSub(user),
      isCollaborator: get(form, 'project.owner.id') != user.id,
    };
  },
  dispatch =>
    bindActionCreators({hideForm, clearFormError, createResource}, dispatch)
)(NewResourceModal);

import {Route, Switch} from 'react-router-dom';

import React from 'react';

const Footer = () => (
  <div className="py-4">
    <h4 className="uppercase text-xs font-bold text-gray-400 tracking-wider">
      Contact
    </h4>
    <div>help@mockapi.io</div>
  </div>
);

export default function () {
  return (
    <Switch>
      <Route path="/projects" component={Footer} />
      <Route exact path="/" component={Footer} />
    </Switch>
  );
}

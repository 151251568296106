import {motion} from 'framer-motion';
import React from 'react';

const Toggle = ({enabled, onClick}) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={onClick}
      className="h-4 w-10 rounded-lg flex items-center bg-gray-200
      active:bg-gray-300 cursor-pointer">
      <motion.div
        animate={{
          x: enabled ? 16 : 0,
        }}
        transition={spring}
        className={`border h-6 w-6 rounded-full shadow flex items-center
        justify-center font-mono font-bold ${
          enabled
            ? 'bg-emerald-500 border-emerald-500 text-white'
            : 'bg-white text-gray-800 border-gray-300'
        } text-xs`}>
        {enabled ? 'On' : ''}
      </motion.div>
    </div>
  );
};

export default Toggle;

import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import AcceptInviteHandler from '../components/AcceptInviteHandler';
import CloneProjectHandler from '../components/CloneProjectHandler';
import ProjectDetails from '../components/ProjectDetails';
import ProjectList from '../components/ProjectList';
import Toolbar from '../components/Toolbar';
import {connect} from 'react-redux';

class Authenticated extends Component {
  render() {
    const {
      user,
      location: {pathname},
    } = this.props;

    if (!user.authorized) {
      return <Redirect to={{pathname: '/login'}} />;
    }

    return (
      <React.Fragment>
        <Switch>
          <Route exact key={pathname} path="/projects" component={Toolbar} />
          <Route key={pathname} path="/projects/:id" component={Toolbar} />
        </Switch>
        <Switch>
          <Route
            key={pathname}
            exact
            path="/clone/:id"
            component={CloneProjectHandler}
          />
          <Route
            key={pathname}
            exact
            path="/projects"
            component={ProjectList}
          />
          <Route
            key={pathname}
            path="/projects/:id"
            component={ProjectDetails}
          />
          <Route
            key={pathname}
            path="/collaborator/:id/accept"
            component={AcceptInviteHandler}
          />
          <Redirect to="/projects" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connect(({user}) => ({user}))(Authenticated);

import {Alert, Input} from './Form';
import {AnimatePresence, motion} from 'framer-motion';
import {Button, IconButton} from './Button';
import {COLLABORATORS, formStatus} from '../reducers/forms';
import React, {useState} from 'react';
import {
  useAddCollaborator,
  useCollaborators,
  useRemoveCollaborator,
} from '../hooks/collaborators';

import FeatureUnavailableAlert from './FeatureUnavailableAlert';
import InviteStatus from './InviteStatus';
import Modal from './Modal';
import Tag from './Tag';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hideForm} from '../actions/forms';
import {isEmail} from 'validator';
import {updateCollaborators} from '../actions/projects';

const CollaboratorsModal = ({form, hideForm, user}) => {
  return (
    <Modal
      size="lg"
      show={form.show}
      onHide={() => !form.isFetching && hideForm(COLLABORATORS)}
      title="Manage collaborators">
      <CollaboratorsList project={form.project} user={user} />
    </Modal>
  );
};

function CollaboratorsList({project, user}) {
  const query = useCollaborators(project);
  const isOwner = project.owner.id === user.id;
  const isSubscribed = user.stripeSubscriptionStatus === 'active';

  return (
    <div className="space-y-4 p-4">
      <p className="text-base font-mono">
        Collaborators can <strong>create</strong>, <strong>update</strong>,
        <strong>delete</strong> resources in this project and generate data.
      </p>
      {/* Show upsell */}
      {isOwner && !isSubscribed && <FeatureUnavailableAlert />}
      {/* Invite form */}
      {isOwner && isSubscribed && <AddCollaboratorForm project={project} />}
      {/* Table header */}
      <div className="p-2 flex items-center justify-between sm:gap-2 border-b border-gray-300">
        <div className="col-span-6 text-gray-500 text-xs font-mono font-bold uppercase tracking-wider">
          user
        </div>
        <div className="col-span-1 text-gray-500 text-xs font-mono font-bold uppercase tracking-wider pr-9">
          status
        </div>
      </div>
      {/* Loading state */}
      {query.isFetching && query.data.length === 0 && (
        <div className="animate-pulse p-2 h-10">
          <div className="flex items-center justify-between">
            <div className="h-2 w-40 bg-slate-300 rounded"></div>
            <div className="h-2 w-20 bg-slate-300 rounded"></div>
          </div>
        </div>
      )}
      {/* Error */}
      {query.isError && (
        <motion.div
          key="error"
          layout
          transition={{type: 'tween'}}
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: 'auto'}}>
          <Alert variant="danger">
            There was an error processing your request. Please try again.
          </Alert>
        </motion.div>
      )}
      {/* Empty state */}
      {!query.isFetching && !query.isError && query.data.length === 0 && (
        <motion.div
          key="empty"
          layout
          transition={{type: 'tween'}}
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: 'auto'}}>
          <div className="flex items-center justify-center h-32">
            <p className="text-sm text-gray-500">No collaborators yet</p>
          </div>
        </motion.div>
      )}
      {query.isFetched && (
        <div>
          <AnimatePresence initial={false}>
            {project.owner.id !== user.id && (
              <div className="pr-8">
                <Owner owner={project.owner} />
              </div>
            )}
            {query.data.map(collaborator => (
              <motion.div
                key={collaborator.id}
                layout
                transition={{type: 'tween'}}
                initial={{opacity: 0, height: 0}}
                animate={{opacity: 1, height: 'auto'}}
                exit={{opacity: 0, height: 0}}>
                <Collaborator
                  collaborator={collaborator}
                  isOwner={isOwner}
                  isCollaborator={user.id === collaborator.user}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}

function Owner({owner}) {
  return (
    <div className="flex items-center justify-between gap-2 rounded-lg p-2">
      <div className="flex text-base font-mono">
        {owner.email || owner.name}
      </div>
      <div className="flex items-center justify-end gap-2">
        <Tag variant="primary" label="owner" />
      </div>
    </div>
  );
}

function Collaborator({collaborator, isOwner, isCollaborator}) {
  const remove = useRemoveCollaborator(collaborator);

  return (
    <div
      className={`flex items-center justify-between gap-2 rounded-lg p-2 ${
        remove.isPending ? 'bg-amber-100' : ''
      }`}>
      <div className="flex text-base font-mono">{collaborator.email}</div>
      <div className="flex items-center justify-end gap-2">
        <InviteStatus status={collaborator.status} />
        <div className="w-6">
          {(isOwner || isCollaborator) && (
            <form
              disabled={remove.isPending}
              onSubmit={event => {
                event.preventDefault();
                remove.mutate(collaborator.id, {
                  onSuccess: () => {
                    if (isCollaborator) {
                      window.location.href = '/projects';
                    }
                  },
                });
              }}>
              <IconButton
                tooltip="Remove"
                type="submit"
                size="sm"
                iconName="X"
                disabled={remove.isPending}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

function AddCollaboratorForm({project}) {
  const [email, setEmail] = useState('');
  const add = useAddCollaborator(project);

  return (
    <form
      disabled={add.isPending}
      className="space-y-6"
      onSubmit={event => {
        event.preventDefault();
        add.mutate({email}, {onSuccess: () => setEmail('')});
      }}>
      {add.isError && (
        <Alert variant="danger">
          There was an error processing your request. Please try again.
        </Alert>
      )}
      <div className="grid grid-cols-8 items-center gap-1 sm:gap-2">
        <div className="col-span-6">
          <Input
            disabled={add.isPending}
            placeholder="Enter email"
            value={email}
            onChange={event => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <Button
            disabled={add.isPending || !isEmail(email)}
            type="submit"
            fullWidth
            size="sm"
            variant="primary">
            {add.isPending ? 'Inviting...' : 'Invite'}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default connect(
  ({forms, user}) => ({form: formStatus(forms, COLLABORATORS), user}),
  dispatch => bindActionCreators({hideForm, updateCollaborators}, dispatch)
)(CollaboratorsModal);

import {Alert, Form, FormActions, TextArea, InputGroup} from '../Form';
import React, {Component} from 'react';

import {getToken} from '../../actions/utils';
import isArray from 'lodash/isArray';

class ResourceDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      changed: false,
      value: '',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const {form, resource} = this.props;
    const url = `/api/mocks/${form.project.id}/resources/${resource.id}/data`;

    try {
      const res = await fetch(url, {
        headers: {'Content-Type': 'application/json', token: getToken()},
      });
      const data = await res.json();
      this.setState({
        value: JSON.stringify(data, null, '  '),
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({isLoading: false});
    }
  };

  render() {
    const {resource, form} = this.props;
    const {isLoading, changed, value} = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <InputGroup
          label="Resource data"
          helpText={
            <span>
              Edit/replace data for <strong>{resource.name}</strong> resource.
              Data must be an array and a valid JSON.
            </span>
          }>
          <div className="space-y-2">
            {form.error && <Alert variant="danger">{form.error.message}</Alert>}
            <TextArea
              placeholder={isLoading ? 'Loading data...' : 'Resource data'}
              style={{width: '100%', fontSize: 12}}
              disabled={form.isFetching}
              value={value}
              onChange={newValue => this.onChange(newValue)}
              onBlur={this.validate}
            />
          </div>
        </InputGroup>
        <FormActions
          primary={{
            label: form.isFetching ? 'Updating...' : 'Update',
            disabled: form.isFetching || form.error || !changed,
          }}
          secondary={{
            disabled: form.isFetching,
            onClick: this.props.onHide,
          }}
        />
      </Form>
    );
  }

  onSubmit = event => {
    const {form} = this.props;
    event.preventDefault();
    this.props.updateResourceData(
      this.state.value,
      form.project.id,
      form.resourceId
    );
  };

  onChange = newValue => {
    this.props.clearError();

    this.setState({
      changed: true,
      value: newValue,
    });
  };

  validate = () => {
    const {value} = this.state;
    try {
      const json = JSON.parse(value);
      if (!isArray(json)) {
        throw new Error('Data must be an array');
      }
      this.setState({value: JSON.stringify(json, null, '  ')});
    } catch (error) {
      if (error.message === 'Data must be an array') {
        return this.props.onError(error);
      }

      this.props.onError(new Error('Invalid JSON'));
    }
  };
}

export default ResourceDataForm;

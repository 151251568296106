import React, {Component} from 'react';
import {Transition, animated} from 'react-spring';
import {bindActionCreators, compose} from 'redux';

import Nodata from '../Nodata';
import ProjectDetailsToolbar from './ProjectDetailsToolbar';
import ResourceTree from '../ResourceTree';
import {connect} from 'react-redux';
import {detailsById} from '../../reducers/projectDetails';
import {fetchProjectDetails} from '../../actions/projectDetails';
import {withRouter} from 'react-router-dom';

class ProjectDetails extends Component {
  componentDidMount() {
    const {match} = this.props;
    this.props.fetchProjectDetails(match.params.id);
  }

  render() {
    const {project} = this.props;

    let items = [];

    if (project) {
      items = [
        {
          key: 'header',
          component: ProjectDetailsToolbar,
          initStyles: {opacity: 0, y: 15},
        },
      ];

      if (project.resources.length) {
        items = [
          ...items,
          {
            key: 'resurceTree',
            component: ResourceTree,
            initStyles: {opacity: 0.1, y: 35},
          },
        ];
      } else {
        items = [
          ...items,
          {
            key: 'nodata',
            component: () => <Nodata message="No resources yet..." />,
            initStyles: {opacity: 0.1, y: 35},
          },
        ];
      }
    }

    return (
      <Transition
        native
        items={items}
        keys={items.map(({key}) => key)}
        from={item => item.initStyles}
        enter={{opacity: 1, y: 0}}
        leave={{
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          y: -20,
        }}>
        {items.map(item => ({y, ...styles}) => (
          <animated.div
            style={{
              ...styles,
              transform: y.interpolate(y => `translateY(${y}px)`),
            }}>
            <item.component />
          </animated.div>
        ))}
      </Transition>
    );
  }
}

export default compose(
  withRouter,
  connect(
    ({projectDetails}, {match}) => ({
      project: detailsById(projectDetails, match.params.id),
    }),
    dispatch => bindActionCreators({fetchProjectDetails}, dispatch)
  )
)(ProjectDetails);

import {MAGIC_LINK, formStatus} from '../reducers/forms';
import Modal, {Body} from './Modal';

import {FormActions, Input} from './Form';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {hideForm} from '../actions/forms';
import Tooltip from './Tooltip';
import useCopyText from '../hooks/useCopyText';

const MagicLinkModal = ({form, hideForm}) => {
  const [copied, copyTextHandler] = useCopyText();
  const value = `https://mockapi.io/clone/${get(form, 'project.id')}`;

  return (
    <Modal
      size="md"
      show={form.show}
      onHide={() => !form.isFetching && hideForm(MAGIC_LINK)}
      title="Magic link">
      <Body>
        <p className="font-mono">
          Share below link with someone who needs to clone{' '}
          <strong>{get(form, 'project.name')}</strong> project
        </p>
        <Tooltip
          content={copied ? '✅ Copied!' : 'Click to copy'}
          trigger={
            <div>
              <Input
                readOnly
                onClick={() => copyTextHandler(value)}
                value={value}
              />
            </div>
          }
        />

        <FormActions
          primary={{
            label: 'Close',
            variant: 'secondary',
            onClick: () => hideForm(MAGIC_LINK),
          }}
        />
      </Body>
    </Modal>
  );
};

export default connect(
  ({forms}) => ({form: formStatus(forms, MAGIC_LINK)}),
  dispatch => bindActionCreators({hideForm}, dispatch)
)(MagicLinkModal);

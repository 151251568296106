import React from 'react';
import Icon from './Icon';
import {Button} from './Button';

const Nodata = ({message, button}) => (
  <div className="flex items-center justify-center h-48">
    <div className="flex items-center mb-5">
      <div
        className="w-5 h-5 mr-3"
        style={{width: 18, height: 18, marginRight: 10}}>
        <Icon name="Search" />
      </div>
      <p>{message}</p>
    </div>
    {button && <Button {...button}>{button.text}</Button>}
  </div>
);

export default Nodata;

import {Alert, TextArea} from '../Form';
import React, {Component} from 'react';

import Toggle from './Toggle';

const Example = () => (
  <div className="space-y-2">
    <TextArea
      readOnly
      value={`$mockData  
`}
    />
    <TextArea
      readOnly
      value={`{
  "requestId": "$datatype.uuid",
  "items": "$mockData",
  "count": "$count",
  "anyKey": "anyValue"
}`}
    />
  </div>
);

const Endpoint = ({i, error, item, isActiveSub, onChange, onError}) => {
  const canEdit = item.response !== '$mockData' || isActiveSub;

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 text-sm">
        <Toggle
          enabled={item.enabled}
          onClick={() =>
            isActiveSub && onChange(i, {...item, enabled: !item.enabled})
          }
        />
        <div className="uppercase tracking-wider font-bold text-emerald-600">
          {item.method}
        </div>
        <div className="text-gray-900 font-medium">{item.url}</div>
      </div>
      <TextArea
        disabled={!item.enabled || !canEdit}
        value={item.response}
        onChange={newValue => onChange(i, {...item, response: newValue})}
        onBlur={() => {
          if (typeof item.response === 'string') {
            let response = item.response.trim();
            if (!response) {
              onChange(i, {...item, response: '$mockData'});
            } else if (response === '$mockData') {
              onChange(i, {...item, response});
            } else {
              try {
                const json = JSON.parse(response);
                onChange(i, {
                  ...item,
                  response: JSON.stringify(json, null, '  '),
                });
              } catch (error) {
                onError(
                  i,
                  new Error('Invalid response, must be $mockData or JSON')
                );
              }
            }
          }
        }}
      />
      {error && (
        <Alert textOnly variant="danger">
          {error.message}
        </Alert>
      )}
    </div>
  );
};

class EndpointsEditor extends Component {
  render() {
    const {errors, endpoints, isActiveSub, onEndpointChange, onEndpointError} =
      this.props;
    return (
      <div className="space-y-6">
        <Example />
        {endpoints.map((ep, i) => (
          <Endpoint
            key={i}
            i={i}
            item={ep}
            isActiveSub={isActiveSub}
            error={errors[i]}
            onChange={onEndpointChange}
            onError={onEndpointError}
          />
        ))}
      </div>
    );
  }
}

export default EndpointsEditor;

import {Alert, Form, FormActions, Input, InputGroup} from '../Form';
import React, {Component} from 'react';
import {validPrefix, validProjectName} from '../../utils/validators';

import {formatPrefix} from '../../utils/formaters';
import get from 'lodash/get';

const getInitialState = project => ({
  name: get(project, 'name', ''),
  prefix: get(project, 'prefix', ''),
});

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props.project);
  }

  render() {
    const {clone, edit, form, onHide} = this.props;
    const {name, prefix} = this.state;
    const disableSubmitBtn = form.isFetching || form.error || !name;

    let submitBtnText = '';

    if (clone) {
      submitBtnText = form.isFetching ? 'Cloning...' : 'Clone';
    } else if (edit) {
      submitBtnText = form.isFetching ? 'Updating...' : 'Update';
    } else {
      submitBtnText = form.isFetching ? 'Creating...' : 'Create';
    }

    return (
      <Form onSubmit={this.onSubmit}>
        {form.error && <Alert variant="danger">{form.error.message}</Alert>}
        <InputGroup label="Name">
          <Input
            autoFocus
            placeholder="Example: Todo App, Project X..."
            name="name"
            type="text"
            disabled={form.isFetching}
            value={name}
            onChange={this.onNameChange}
          />
        </InputGroup>
        <InputGroup
          label="API Prefix"
          helpText="Add API prefix to all endpoints in this project.">
          <Input
            placeholder="Example: /api/v1"
            name="prefix"
            type="text"
            disabled={form.isFetching}
            value={prefix}
            onChange={this.onPrefixChange}
            onBlur={({target}) =>
              this.setState({prefix: formatPrefix(target.value)})
            }
          />
        </InputGroup>
        <FormActions
          primary={{
            label: submitBtnText,
            disabled: disableSubmitBtn,
          }}
          secondary={{
            disabled: form.isFetching,
            onClick: onHide,
            label: 'Cancel',
          }}
        />
      </Form>
    );
  }

  onPrefixChange = ({target: {name, value}}) => {
    if (validPrefix(value)) {
      this.setState({[name]: value});
      this.props.clearFormError();
    }
  };

  onNameChange = ({target: {name, value}}) => {
    if (validProjectName(value)) {
      this.setState({[name]: value});
      this.props.clearFormError();
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const {form, project, onSubmit} = this.props;
    const {name} = this.state;
    const prefix = formatPrefix(this.state.prefix);
    const id = get(project, 'id');

    if (!form.isFetching && name) {
      onSubmit({name, prefix, id});
    }
  };
}

export default ProjectForm;

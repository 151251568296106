import {DONATE, NEW_RESOURCE, formStatus} from '../../reducers/forms';
import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {first, get} from 'lodash';
import {generateAll, resetAll} from '../../actions/data';

import {Button} from '../Button';
import Tooltip from '../Tooltip';
import {connect} from 'react-redux';
import {detailsById} from '../../reducers/projectDetails';
import {showForm} from '../../actions/forms';
import {withRouter} from 'react-router-dom';

function count(resources = []) {
  return resources.reduce(
    (result, r) => result + count(r.children),
    resources.length
  );
}

function canNotCreateResource({user, project}) {
  let result;

  if (
    user.id !== project.owner.id ||
    user.stripeSubscriptionStatus === 'active' ||
    user.companyLicense
  ) {
    result = false;
  } else if (user.stripeSubscriptionStatus === 'canceled') {
    result = true;
  } else {
    result = count(project.resources) >= 2;
  }
  return result;
}

export class ProjectDetailsToolbar extends Component {
  state = {
    generating: false,
    resetting: false,
  };

  componentDidMount() {
    const {isFetching, prompt, project} = this.props;
    if (!isFetching && prompt && !get(project, 'resources', []).length) {
      setTimeout(() => this.props.showForm(NEW_RESOURCE, project), 500);
    }
  }

  componentWillReceiveProps({isFetching, prompt, project}) {
    if (!isFetching && prompt && !get(project, 'resources', []).length) {
      setTimeout(() => this.props.showForm(NEW_RESOURCE, project), 500);
    } else if (!isFetching && this.props.isFetching) {
      this.setState({
        generating: false,
        resetting: false,
      });
    }
  }

  render() {
    const {project, isFetching} = this.props;
    const {generating, resetting} = this.state;
    const demo = project.id === 'fake';
    const resource = first(project.resources);
    let href = `https://${project.resourceTreeId}.mockapi.io`;
    if (project.prefix) {
      href += project.prefix;
    }
    if (resource) {
      href += `/${resource.name}`;
    } else {
      href = '#';
    }

    return (
      <div className="rounded-3xl bg-white shadow -mx-4 sm:mx-auto">
        <div className="text-xl font-bold font-mono pt-4 px-4">
          API endpoint
        </div>
        <div className="overflow-x-auto py-2 px-4">
          <div className="h-7">
            <a
              href={href}
              target={href === '#' ? undefined : '_blank'}
              referrerPolicy="no-referrer"
              className="text-blue-700 text-base font-mono">
              https://
              {demo ? (
                'SECRET'
              ) : (
                <Tooltip
                  content="Project secret"
                  trigger={
                    <span className="p-1 rounded-lg bg-blue-100 border-blue-400 font-bold">
                      {project.resourceTreeId}
                    </span>
                  }
                />
              )}
              .mockapi.io
              {project.prefix && (
                <Tooltip
                  content="API prefix"
                  trigger={
                    <span className="p-1 rounded-lg bg-violet-100 border-violet-400 text-violet-700 font-bold">
                      {project.prefix}
                    </span>
                  }
                />
              )}
              /
              <Tooltip
                trigger={
                  <span className="p-1 rounded-lg bg-orange-100 border-orange-400 text-orange-700 font-bold">
                    :endpoint
                  </span>
                }
                content={`Resource name${
                  resource ? ' (Ex: ' + resource.name + ')' : ''
                }`}></Tooltip>
            </a>
          </div>
        </div>
        <div className="flex items-center justify-between rounded-bl-3xl rounded-br-3xl p-4 bg-gray-50">
          <div className="flex-grow">
            <Button
              size="sm"
              variant="primary"
              disabled={isFetching || demo}
              onClick={this.showNewResourceModal}>
              New resource
            </Button>
          </div>
          <div className="space-x-2 hidden sm:block">
            <Button
              size="sm"
              disabled={isFetching || !project.resources.length}
              onClick={this.generateAll}>
              {generating ? 'Generating...' : 'Generate all'}
            </Button>
            <Button
              size="sm"
              disabled={isFetching || !project.resources.length}
              onClick={this.resetAll}>
              {resetting ? 'Resetting...' : 'Reset all'}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  showNewResourceModal = () => {
    canNotCreateResource(this.props)
      ? this.props.showForm(DONATE, true)
      : this.props.showForm(NEW_RESOURCE, this.props.project);
  };

  generateAll = () => {
    this.props.generateAll(this.props.project.id);
  };

  resetAll = () => {
    this.props.resetAll(this.props.project.id);
  };
}

export default compose(
  withRouter,
  connect(
    ({user, projectDetails, forms}, {match}) => ({
      isFetching: projectDetails.isFetching,
      prompt: formStatus(forms, NEW_RESOURCE).prompt,
      project: detailsById(projectDetails, match.params.id),
      user,
    }),
    dispatch => bindActionCreators({showForm, generateAll, resetAll}, dispatch)
  )
)(ProjectDetailsToolbar);

import {CLONE_PROJECT, formStatus} from '../../reducers/forms';
import Modal from '../Modal';
import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {clearFormError, hideForm} from '../../actions/forms';

import ProjectForm from './ProjectForm';
import {cloneProject} from '../../actions/projects';
import {connect} from 'react-redux';
import {hasActiveSub} from '../../utils/user';
import {withRouter} from 'react-router-dom';

class NewProjectModal extends Component {
  render() {
    const {history, form, cloneProject, isActiveSub} = this.props;

    return (
      <Modal
        size="md"
        show={form.show}
        onHide={this.onHide}
        title="Clone project">
        <ProjectForm
          clone
          isActiveSub={isActiveSub}
          form={form}
          project={form.project}
          onHide={this.onHide}
          onSubmit={project =>
            cloneProject(
              project,
              (error, id) => id && history.push(`/projects/${id}`)
            )
          }
          clearFormError={this.clearFormError}
        />
      </Modal>
    );
  }

  clearFormError = () => {
    this.props.clearFormError(CLONE_PROJECT);
  };

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(CLONE_PROJECT);
    }
  };
}

export default compose(
  withRouter,
  connect(
    ({forms, user}) => ({
      form: formStatus(forms, CLONE_PROJECT),
      isActiveSub: hasActiveSub(user),
    }),
    dispatch =>
      bindActionCreators({hideForm, clearFormError, cloneProject}, dispatch)
  )
)(NewProjectModal);

import {Spring, animated} from 'react-spring';

import React from 'react';
import Spinner from './Spinner';

export default () => (
  <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
    <Spring native from={{opacity: 0, scale: 0.7}} to={{opacity: 1, scale: 1}}>
      {({opacity, scale}) => (
        <animated.div
          style={{
            opacity,
            transform: scale.interpolate(s => `scale(${s})`),
          }}>
          <Spinner />
        </animated.div>
      )}
    </Spring>
  </div>
);

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {me} from '../actions/user';
import {saveToken} from '../actions/utils';

class CallbackHandler extends Component {
  componentDidMount() {
    const {
      history,
      location: {search},
    } = this.props;
    const token = search.split('=')[1];
    saveToken(token);
    this.props.me(() => history.push({pathname: '/'}));
  }

  render() {
    return <div />;
  }
}

export default connect(null, dispatch => bindActionCreators({me}, dispatch))(
  CallbackHandler
);

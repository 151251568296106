import {Alert, Form, Input, InputGroup} from './Form';
import React, {useEffect, useState} from 'react';

import {Button} from './Button';
import Icon from './Icon';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useRequestPasswordRest} from '../hooks/auth';

export default function ResetPasswordScreen() {
  const passwordReset = useRequestPasswordRest();
  const [email, setEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const disabled = passwordReset.isPending || showConfirmation;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfirmation(false);
    }, 1000 * 60 * 5 /* 5 minutes */);

    return () => clearTimeout(timer);
  }, [showConfirmation]);

  return (
    <motion.div initial={{opacity: 0.5}} animate={{opacity: 1}}>
      <div className="max-w-md mx-auto sm:my-20 space-y-5 font-mono">
        <div className="grid grid-cols-10">
          <Link to="/" className="col-span-2 flex items-center text-blue-700">
            <div className="mr-2">
              <Icon name="ArrowLeft" />
            </div>
            Back
          </Link>
          <h1 className="col-span-6 text-2xl font-mono font-bold text-center">
            Reset password
          </h1>
        </div>
        <div className="rounded-xl bg-white shadow px-2 py-6">
          <Form
            disabled={disabled}
            className="grid grid-cols-1 gap-6 my-6 py-6 border-t-2 border-b-2 border-gray-200"
            onSubmit={event => {
              event.preventDefault();
              passwordReset.mutate(email, {
                onSuccess: () => {
                  setEmail('');
                  setShowConfirmation(true);
                },
              });
            }}>
            {showConfirmation ? (
              <Alert variant="success">
                We have sent you an email with instructions to reset your
                password.
              </Alert>
            ) : null}
            {passwordReset.isError ? (
              <Alert variant="danger">
                There was an error processing your request. Please try again
                later.
              </Alert>
            ) : null}
            <InputGroup label="Enter you email">
              <Input
                required
                placeholder="..."
                name="email"
                type="email"
                disabled={disabled}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </InputGroup>
            <Button
              className="w-full"
              variant="primary"
              type="submit"
              disabled={disabled}
              fullWidth>
              Reset
            </Button>
          </Form>
        </div>
      </div>
    </motion.div>
  );
}

import './App.css';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React, {Component} from 'react';

import Appbar from './components/Appbar';
import Authenticated from './screens/Authenticated';
import CallbackHandler from './screens/CallbackHandler';
import ChangePasswordScreen from './components/ChangePasswordScreen';
import Footer from './components/Footer';
import Landing from './components/Landing';
import Login from './screens/Login';
import ModalLayer from './components/ModalLayer';
import Notifier from './components/Notifier';
import ResetPasswordScreen from './components/ResetPasswordScreen';
import Signup from './screens/Signup';
import Splash from './components/Splash';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {me} from './actions/user';

const queryClient = new QueryClient();

class App extends Component {
  componentDidMount() {
    this.props.me();
  }

  render() {
    const {
      user: {isFetching, authorized},
    } = this.props;

    if (isFetching) {
      return <Splash text="Authorizing..." />;
    }

    if (authorized) {
      return (
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <div className="min-h-screen font-mono text-gray-900 overflow-x-hidden">
              <div className="min-h-screen">
                <Appbar />
                <Notifier />
                <ModalLayer />
                <div className="max-w-6xl mx-auto px-3 sm:px-4">
                  <Switch>
                    <Route path="/callback" component={CallbackHandler} />
                    <Route path="/" component={Authenticated} />
                  </Switch>
                </div>
              </div>
              <div className="max-w-6xl mx-auto p-4">
                <Footer />
              </div>
            </div>
          </QueryClientProvider>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <div className="min-h-full font-mono text-gray-900  overflow-x-hidden">
            <Switch>
              <Route path="/" component={Appbar} />
            </Switch>
            <Notifier />
            <div className="max-w-6xl mx-auto p-3 sm:p-4">
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route
                  exact
                  path="/reset_password"
                  component={ResetPasswordScreen}
                />
                <Route
                  exact
                  path="/reset_password/:token"
                  component={ChangePasswordScreen}
                />
                <Route path="/callback" component={CallbackHandler} />
                <Route path="/" component={Landing} />
              </Switch>
            </div>
            <div className="max-w-6xl mx-auto p-4">
              <Footer />
            </div>
          </div>
        </QueryClientProvider>
      </BrowserRouter>
    );
  }
}

export default connect(
  ({user}) => ({user}),
  dispatch => bindActionCreators({me}, dispatch)
)(App);

import {COLLABORATORS, DONATE, NEW_PROJECT} from '../reducers/forms';
import React, {Component} from 'react';
import {Trail, animated, config} from 'react-spring';
import {bindActionCreators, compose} from 'redux';

import Collaborators from './Collaborators';
import {IconButton} from './Button';
import {Link} from 'react-router-dom';
import ProjectActions from './ProjectActions';
import ProjectLabel from './ProjectLabel';
// import ProjectOwner from './ProjectOwner';
import Spinner from './Spinner';
import {connect} from 'react-redux';
import {detailsById} from '../reducers/projectDetails';
import get from 'lodash/get';
import {showForm} from '../actions/forms';
import {withRouter} from 'react-router-dom';

const Separator = () => (
  <div className="text-gray-400 font-bold after:content-['/']" />
);

class Toolbar extends Component {
  render() {
    const {
      projectIds,
      projectsFetching,
      projectDetails,
      projectDetailsFetching,
      user,
      match: {params},
    } = this.props;

    let spinnerEl = null;

    if (
      (!params.id && !projectIds.length && projectsFetching) ||
      (params.id && !projectDetails && projectDetailsFetching)
    ) {
      spinnerEl = <Spinner />;
    }

    return (
      <div className="flex items-center justify-between flex-nowrap">
        <div className="flex items-center flex-nowrap gap-3 h-16 text-base font-mono font-medium">
          {params.id ? (
            <Link to="/projects" className="text-blue-700">
              Projects
            </Link>
          ) : (
            <div>Projects</div>
          )}
          <Separator />
          {spinnerEl || this.renderAnimatedToolbarItems()}
        </div>
        {projectDetails && (
          <Collaborators
            project={projectDetails}
            user={user}
            showModal={() => this.props.showForm(COLLABORATORS, projectDetails)}
          />
        )}
      </div>
    );
  }

  renderAnimatedToolbarItems = () => {
    const {
      user,
      projectIds,
      projectDetails,
      match: {params},
    } = this.props;

    const canCreateProject =
      user.companyLicense ||
      user.stripeSubscriptionStatus === 'active' ||
      (user.stripeSubscriptionStatus !== 'canceled' && projectIds.length === 0);

    let isOwner = user.id === get(projectDetails, 'owner.id');
    let items = [];
    let ownerWarning = null;

    if (params.id) {
      if (projectDetails) {
        items = [
          {
            key: 'project',
            component: ProjectLabel,
            props: {
              project: projectDetails,
              showCollaboratorsCount: !isOwner,
            },
          },
        ];

        if (isOwner) {
          items = [
            ...items,
            {
              key: 'collaborate',
              // rotate: true,
              component: props => <IconButton {...props} />,
              props: {
                iconName: 'Users',
                tooltip: 'Collaborators',
                onClick: () =>
                  this.props.showForm(COLLABORATORS, projectDetails),
              },
            },
            {
              key: 'settings',
              // rotate: true,
              component: props => (
                <ProjectActions
                  {...props}
                  orient={
                    get(window, 'innerWidth', 1000) < 500 ? 'right' : 'left'
                  }
                />
              ),
              props: {project: projectDetails},
            },
          ];
        } else {
        }
      }
    } else {
      items = [
        {
          key: 'newProject',
          // rotate: true,
          component: IconButton,
          props: {
            iconName: 'Plus',
            variant: 'primary',
            tooltip: 'New project',
            onClick: () =>
              canCreateProject
                ? this.props.showForm(NEW_PROJECT)
                : this.props.showForm(DONATE, true),
          },
        },
      ];
    }

    return (
      <div className="flex flex-nowrap gap-3 items-start">
        <Trail
          native
          config={config.stiff}
          items={items}
          keys={items.map(({key}) => key)}
          from={{t: 0}}
          to={{t: 1}}>
          {items.map(item => ({t}) => (
            <animated.div
              key={item.key}
              className={
                item.key === 'project' ? 'max-w-[145px] sm:max-w-full' : ''
              }
              style={{
                zIndex: 1,
                transformOrigin: 'center',
                opacity: t,
                transform: t.interpolate({
                  range: [0, 1],
                  output: item.rotate
                    ? ['rotate(-90deg) scale(0.3)', 'rotate(0deg) scale(1)']
                    : ['scale(0.3)', 'scale(1)'],
                }),
              }}>
              <item.component {...item.props} />
            </animated.div>
          ))}
        </Trail>
        {ownerWarning}
      </div>
    );
  };
}

export default compose(
  withRouter,
  connect(
    ({user, projects, projectDetails}, {match: {params}}) => ({
      user,
      projectDetails: params.id ? detailsById(projectDetails, params.id) : null,
      projectDetailsFetching: projectDetails.isFetching,
      projectIds: projects.ids,
      projectsFetching: projects.isFetching,
    }),
    dispatch => bindActionCreators({showForm}, dispatch)
  )
)(Toolbar);

import {EDIT_RESOURCE, formStatus} from '../../reducers/forms';
import React, {Component} from 'react';
import {clearFormError, hideForm} from '../../actions/forms';
import {getParentResource, getResource} from '../../reducers/projectDetails';
import {hasActiveSub, hasCollabAccess} from '../../utils/user';

import Modal from '../Modal';
import ResourceForm from './ResourceForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {updateResource} from '../../actions/resources';

class EditResourceModal extends Component {
  render() {
    const {form, resource, parentResource, canNotCreateResource, isActiveSub} =
      this.props;

    return (
      <Modal
        size="xl"
        show={form.show}
        onHide={this.onHide}
        title={`Edit resource - ${get(resource, 'name')}`}>
        <ResourceForm
          edit
          disabled={canNotCreateResource}
          form={form}
          resource={resource}
          parentResource={parentResource}
          onHide={this.onHide}
          onSubmit={this.props.updateResource}
          clearFormError={this.clearFormError}
          isActiveSub={isActiveSub}
        />
      </Modal>
    );
  }

  clearFormError = () => {
    this.props.clearFormError(EDIT_RESOURCE);
  };

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(EDIT_RESOURCE);
    }
  };
}

export default connect(
  ({forms, projectDetails, user}) => {
    const form = formStatus(forms, EDIT_RESOURCE);
    const resource =
      form.project && form.resourceId
        ? getResource(projectDetails, form.project.id, form.resourceId)
        : null;
    const parentResource =
      form.project && form.resourceId
        ? getParentResource(projectDetails, form.project.id, form.resourceId)
        : null;

    return {
      form,
      resource,
      parentResource,
      user,
      isActiveSub:
        hasActiveSub(user) || user.id !== get(form, 'project.owner.id'),
    };
  },
  dispatch =>
    bindActionCreators({hideForm, clearFormError, updateResource}, dispatch)
)(EditResourceModal);

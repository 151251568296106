import {getToken} from '../actions/utils';

export function requestPasswordRest(email) {
  const url = `/auth/reset_password`;
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({email}),
  }).then(res => res.json());
}

export function resetPassword({password, token}) {
  const url = `/auth/reset_password/${token}`;
  const headers = {'Content-Type': 'application/json', token: getToken()};

  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({password}),
  }).then(res => res.json());
}

import {Alert, TextArea} from '../Form';
import React, {Component} from 'react';

const Example = () => (
  <TextArea
    readOnly
    value={`{
  "username": "$internet.userName",
  "knownIps": ["$internet.ip", "$internet.ipv6"],
  "profile": {
    "firstName": "$name.firstName",
    "lastName": "$name.lastName",
    "staticData": [100, 200, 300]
  }
}`}
  />
);

class TemplateEditor extends Component {
  render() {
    const {value, error, onChange, onError, isActiveSub} = this.props;

    return (
      <div className="space-y-2">
        <Example />
        <TextArea
          disabled={value === '' && !isActiveSub}
          placeholder="JSON template"
          value={value}
          onChange={value => onChange(value)}
          onBlur={() => {
            if (!value.trim()) {
              return onChange(value.trim());
            }
            try {
              const json = JSON.parse(value);
              onChange(JSON.stringify(json, null, '  '));
            } catch (error) {
              onError(new Error('Invalid JSON'));
            }
          }}
        />
        {error && (
          <Alert textOnly variant="danger">
            {error.message}
          </Alert>
        )}
      </div>
    );
  }
}

export default TemplateEditor;

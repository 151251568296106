import React, {Component} from 'react';

import Notification from './Notification';
import {AnimatePresence, motion} from 'framer-motion';
import {bindActionCreators} from 'redux';
import {clearNotifications} from '../../actions/notifications';
import {connect} from 'react-redux';

class Notifier extends Component {
  timeoutId = null;

  componentWillReceiveProps({notifications}) {
    if (notifications.length) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.props.clearNotifications();
      }, 2500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const {notifications} = this.props;

    return (
      <div className="fixed bottom-3 left-3 right-3 z-50 pointer-events-none">
        <div className="flex flex-col items-stretch sm:items-end gap-1 max-w-6xl mx-auto">
          <AnimatePresence initial={false}>
            {notifications.map(notif => (
              <motion.div
                key={notif.id}
                layout
                positionTransition
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0}}
                transition={{type: 'tween'}}>
                <Notification data={notif} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    );
  }
}

export default connect(
  ({notifications}) => ({notifications}),
  dispatch => bindActionCreators({clearNotifications}, dispatch)
)(Notifier);

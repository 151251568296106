import Modal from '../Modal';
import {RESOURCE_DATA, formStatus} from '../../reducers/forms';
import React, {Component} from 'react';
import {clearFormError, handleFormError, hideForm} from '../../actions/forms';
import {updateResourceData} from '../../actions/data';

import ResourceDataForm from './ResourceDataForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {getData} from '../../reducers/data';
import {getResource} from '../../reducers/projectDetails';

class ResourceDataModal extends Component {
  render() {
    const {form, resource} = this.props;

    return (
      <Modal
        key="blah2"
        size="xl"
        show={form.show}
        onHide={this.onHide}
        title={`Resource data - ${get(resource, 'name')}`}>
        <ResourceDataForm
          resource={resource}
          form={form}
          onHide={this.onHide}
          onError={this.onError}
          clearError={this.clearError}
          updateResourceData={this.props.updateResourceData}
        />
      </Modal>
    );
  }

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(RESOURCE_DATA);
    }
  };

  onError = error => this.props.handleFormError(RESOURCE_DATA, error);
  clearError = () => this.props.clearFormError(RESOURCE_DATA);
}

export default connect(
  ({forms, projectDetails, data}) => {
    const form = formStatus(forms, RESOURCE_DATA);
    const resource =
      get(form, 'project.id') && form.resourceId
        ? getResource(projectDetails, form.project.id, form.resourceId)
        : null;

    const resourceData = resource ? getData(data, resource.id) : null;

    return {form, resource, resourceData};
  },
  dispatch =>
    bindActionCreators(
      {
        hideForm,
        updateResourceData,
        clearFormError,
        handleFormError,
      },
      dispatch
    )
)(ResourceDataModal);

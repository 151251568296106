import React from 'react';

export default function Spinner() {
  return (
    <div
      className={`
      w-5 h-5 rounded-full animate-spin
      after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:rounded-full after:border-2 after:border-transparent after:border-t-blue-600
      before:content-[''] before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-full before:border-2 before:border-gray-300
    `}
    />
  );
}

export default [
  {value: 'address.buildingNumber', label: 'address.buildingNumber'},
  {value: 'address.cardinalDirection', label: 'address.cardinalDirection'},
  {value: 'address.city', label: 'address.city'},
  {value: 'address.cityName', label: 'address.cityName'},
  {value: 'address.country', label: 'address.country'},
  {value: 'address.countryCode', label: 'address.countryCode'},
  {value: 'address.county', label: 'address.county'},
  {value: 'address.direction', label: 'address.direction'},
  {value: 'address.latitude', label: 'address.latitude'},
  {value: 'address.longitude', label: 'address.longitude'},
  {value: 'address.nearbyGPSCoordinate', label: 'address.nearbyGPSCoordinate'},
  {value: 'address.ordinalDirection', label: 'address.ordinalDirection'},
  {value: 'address.secondaryAddress', label: 'address.secondaryAddress'},
  {value: 'address.state', label: 'address.state'},
  {value: 'address.stateAbbr', label: 'address.stateAbbr'},
  {value: 'address.streetAddress', label: 'address.streetAddress'},
  {value: 'address.streetName', label: 'address.streetName'},
  {value: 'address.streetPrefix', label: 'address.street'},
  {value: 'address.streetSuffix', label: 'address.street'},
  {value: 'address.timeZone', label: 'address.timeZone'},
  {value: 'address.zipCode', label: 'address.zipCode'},
  {value: 'address.zipCodeByState', label: 'address.zipCodeByState'},
  {value: 'animal.bear', label: 'animal.bear'},
  {value: 'animal.cat', label: 'animal.cat'},
  {value: 'animal.cetacean', label: 'animal.cetacean'},
  {value: 'animal.cow', label: 'animal.cow'},
  {value: 'animal.crocodilia', label: 'animal.crocodilia'},
  {value: 'animal.dog', label: 'animal.dog'},
  {value: 'animal.fish', label: 'animal.fish'},
  {value: 'animal.horse', label: 'animal.horse'},
  {value: 'animal.insect', label: 'animal.insect'},
  {value: 'animal.lion', label: 'animal.lion'},
  {value: 'animal.rabbit', label: 'animal.rabbit'},
  {value: 'animal.rodent', label: 'animal.rodent'},
  {value: 'animal.snake', label: 'animal.snake'},
  {value: 'animal.type', label: 'animal.type'},
  {value: 'color.cmyk', label: 'color.cmyk'},
  {value: 'color.hsl', label: 'color.hsl'},
  {value: 'color.human', label: 'color.human'},
  {value: 'color.hwb', label: 'color.hwb'},
  {value: 'color.lab', label: 'color.lab'},
  {value: 'color.lch', label: 'color.lch'},
  {value: 'color.rgb', label: 'color.rgb'},
  {value: 'commerce.color', label: 'color.human'},
  {value: 'commerce.department', label: 'commerce.department'},
  {value: 'commerce.price', label: 'commerce.price'},
  {value: 'commerce.product', label: 'commerce.product'},
  {value: 'commerce.productAdjective', label: 'commerce.productAdjective'},
  {value: 'commerce.productDescription', label: 'commerce.productDescription'},
  {value: 'commerce.productMaterial', label: 'commerce.productMaterial'},
  {value: 'commerce.productName', label: 'commerce.productName'},
  {value: 'company.bs', label: 'company.bs'},
  {value: 'company.bsAdjective', label: 'company.bsAdjective'},
  {value: 'company.bsBuzz', label: 'company.bsBuzz'},
  {value: 'company.bsNoun', label: 'company.bsNoun'},
  {value: 'company.catchPhrase', label: 'company.catchPhrase'},
  {
    value: 'company.catchPhraseAdjective',
    label: 'company.catchPhraseAdjective',
  },
  {
    value: 'company.catchPhraseDescriptor',
    label: 'company.catchPhraseDescriptor',
  },
  {value: 'company.catchPhraseNoun', label: 'company.catchPhraseNoun'},
  {value: 'company.companyName', label: 'company.name'},
  {value: 'company.companySuffix', label: 'company.companySuffix'},
  {value: 'company.suffixes', label: 'company.suffixes'},
  {value: 'database.collation', label: 'database.collation'},
  {value: 'database.column', label: 'database.column'},
  {value: 'database.engine', label: 'database.engine'},
  {value: 'database.mongodbObjectId', label: 'database.mongodbObjectId'},
  {value: 'database.type', label: 'database.type'},
  {value: 'datatype.array', label: 'datatype.array'},
  {value: 'datatype.boolean', label: 'datatype.boolean'},
  {value: 'datatype.datetime', label: 'datatype.datetime'},
  {value: 'datatype.float', label: 'datatype.float'},
  {value: 'datatype.hexadecimal', label: 'datatype.hexadecimal'},
  {value: 'datatype.json', label: 'datatype.json'},
  {value: 'datatype.number', label: 'datatype.number'},
  {value: 'datatype.string', label: 'datatype.string'},
  {value: 'datatype.uuid', label: 'datatype.uuid'},
  {value: 'date.between', label: 'date.between'},
  {value: 'date.betweens', label: 'date.betweens'},
  {value: 'date.birthdate', label: 'date.birthdate'},
  {value: 'date.future', label: 'date.future'},
  {value: 'date.month', label: 'date.month'},
  {value: 'date.past', label: 'date.past'},
  {value: 'date.recent', label: 'date.recent'},
  {value: 'date.soon', label: 'date.soon'},
  {value: 'date.weekday', label: 'date.weekday'},
  {value: 'finance.account', label: 'finance.account'},
  {value: 'finance.accountName', label: 'finance.accountName'},
  {value: 'finance.amount', label: 'finance.amount'},
  {value: 'finance.bic', label: 'finance.bic'},
  {value: 'finance.bitcoinAddress', label: 'finance.bitcoinAddress'},
  {value: 'finance.creditCardCVV', label: 'finance.creditCardCVV'},
  {value: 'finance.creditCardNumber', label: 'finance.creditCardIssuer'},
  {value: 'finance.currencyCode', label: 'finance.currencyCode'},
  {value: 'finance.currencyName', label: 'finance.currencyName'},
  {value: 'finance.currencySymbol', label: 'finance.currencySymbol'},
  {value: 'finance.ethereumAddress', label: 'finance.ethereumAddress'},
  {value: 'finance.iban', label: 'finance.iban'},
  {value: 'finance.litecoinAddress', label: 'finance.litecoinAddress'},
  {value: 'finance.mask', label: 'finance.mask'},
  {value: 'finance.pin', label: 'finance.pin'},
  {value: 'finance.routingNumber', label: 'finance.routingNumber'},
  {
    value: 'finance.transactionDescription',
    label: 'finance.transactionDescription',
  },
  {value: 'finance.transactionType', label: 'finance.transactionType'},
  {value: 'git.branch', label: 'git.branch'},
  {value: 'git.commitEntry', label: 'git.commitEntry'},
  {value: 'git.commitMessage', label: 'git.commitMessage'},
  {value: 'git.commitSha', label: 'git.commitSha'},
  {value: 'git.shortSha', label: 'git.shortSha'},
  {value: 'hacker.abbreviation', label: 'hacker.abbreviation'},
  {value: 'hacker.adjective', label: 'hacker.adjective'},
  {value: 'hacker.ingverb', label: 'hacker.ingverb'},
  {value: 'hacker.noun', label: 'hacker.noun'},
  {value: 'hacker.phrase', label: 'hacker.phrase'},
  {value: 'hacker.verb', label: 'hacker.verb'},
  {value: 'image.abstract', label: 'image.abstract'},
  {value: 'image.animals', label: 'image.animals'},
  {value: 'image.avatar', label: 'image.avatar'},
  {value: 'image.business', label: 'image.business'},
  {value: 'image.cats', label: 'image.cats'},
  {value: 'image.city', label: 'image.city'},
  {value: 'image.dataUri', label: 'image.dataUri'},
  {value: 'image.fashion', label: 'image.fashion'},
  {value: 'image.food', label: 'image.food'},
  {value: 'image.image', label: 'image.image'},
  {value: 'image.imageUrl', label: 'image.imageUrl'},
  {value: 'image.nature', label: 'image.nature'},
  {value: 'image.nightlife', label: 'image.nightlife'},
  {value: 'image.people', label: 'image.people'},
  {value: 'image.sports', label: 'image.sports'},
  {value: 'image.technics', label: 'image.technics'},
  {value: 'image.transport', label: 'image.transport'},
  {value: 'internet.avatar', label: 'internet.avatar'},
  {value: 'internet.color', label: 'internet.color'},
  {value: 'internet.domainName', label: 'internet.domainName'},
  {value: 'internet.domainSuffix', label: 'internet.domainSuffix'},
  {value: 'internet.domainWord', label: 'internet.domainWord'},
  {value: 'internet.email', label: 'internet.email'},
  {value: 'internet.emoji', label: 'internet.emoji'},
  {value: 'internet.exampleEmail', label: 'internet.exampleEmail'},
  {value: 'internet.httpMethod', label: 'internet.httpMethod'},
  {value: 'internet.httpStatusCode', label: 'internet.httpStatusCode'},
  {value: 'internet.ip', label: 'internet.ip'},
  {value: 'internet.ipv4', label: 'internet.ipv4'},
  {value: 'internet.ipv6', label: 'internet.ipv6'},
  {value: 'internet.mac', label: 'internet.mac'},
  {value: 'internet.password', label: 'internet.password'},
  {value: 'internet.port', label: 'internet.port'},
  {value: 'internet.protocol', label: 'internet.protocol'},
  {value: 'internet.url', label: 'internet.url'},
  {value: 'internet.userAgent', label: 'internet.userAgent'},
  {value: 'internet.userName', label: 'internet.userName'},
  {value: 'lorem.lines', label: 'lorem.lines'},
  {value: 'lorem.paragraph', label: 'lorem.paragraph'},
  {value: 'lorem.paragraphs', label: 'lorem.paragraphs'},
  {value: 'lorem.sentence', label: 'lorem.sentence'},
  {value: 'lorem.sentences', label: 'lorem.sentences'},
  {value: 'lorem.slug', label: 'lorem.slug'},
  {value: 'lorem.text', label: 'lorem.text'},
  {value: 'lorem.word', label: 'lorem.word'},
  {value: 'lorem.words', label: 'lorem.words'},
  {value: 'mersenne.rand', label: 'mersenne.rand'},
  {value: 'music.genre', label: 'music.genre'},
  {value: 'music.songName', label: 'music.songName'},
  {value: 'name.findName', label: 'name.fullName'},
  {value: 'name.firstName', label: 'name.firstName'},
  {value: 'name.gender', label: 'name.gender'},
  {value: 'name.jobArea', label: 'name.jobArea'},
  {value: 'name.jobDescriptor', label: 'name.jobDescriptor'},
  {value: 'name.jobTitle', label: 'name.jobTitle'},
  {value: 'name.jobType', label: 'name.jobType'},
  {value: 'name.lastName', label: 'name.lastName'},
  {value: 'name.middleName', label: 'name.middleName'},
  {value: 'name.prefix', label: 'name.prefix'},
  {value: 'name.sex', label: 'name.sex'},
  {value: 'name.sexType', label: 'name.sexType'},
  {value: 'name.suffix', label: 'name.suffix'},
  {value: 'phone.imei', label: 'phone.imei'},
  {value: 'phone.phoneFormats', label: 'phone.number'},
  {value: 'phone.phoneNumber', label: 'phone.number'},
  {value: 'phone.phoneNumberFormat', label: 'phone.phoneNumberFormat'},
  {value: 'random.alpha', label: 'random.alpha'},
  {value: 'random.alphaNumeric', label: 'random.alphaNumeric'},
  {value: 'random.locale', label: 'random.locale'},
  {value: 'random.numeric', label: 'random.numeric'},
  {value: 'random.word', label: 'random.word'},
  {value: 'random.words', label: 'random.words'},
  {value: 'science.chemicalElement', label: 'science.chemicalElement'},
  {value: 'science.unit', label: 'science.unit'},
  {value: 'system.commonFileExt', label: 'system.commonFileExt'},
  {value: 'system.commonFileName', label: 'system.commonFileName'},
  {value: 'system.commonFileType', label: 'system.commonFileType'},
  {value: 'system.cron', label: 'system.cron'},
  {value: 'system.directoryPath', label: 'system.directoryPath'},
  {value: 'system.fileExt', label: 'system.fileExt'},
  {value: 'system.fileName', label: 'system.fileName'},
  {value: 'system.filePath', label: 'system.filePath'},
  {value: 'system.fileType', label: 'system.fileType'},
  {value: 'system.mimeType', label: 'system.mimeType'},
  {value: 'system.networkInterface', label: 'system.networkInterface'},
  {value: 'system.semver', label: 'system.semver'},
  {value: 'vehicle.bicycle', label: 'vehicle.bicycle'},
  {value: 'vehicle.color', label: 'vehicle.color'},
  {value: 'vehicle.fuel', label: 'vehicle.fuel'},
  {value: 'vehicle.manufacturer', label: 'vehicle.manufacturer'},
  {value: 'vehicle.model', label: 'vehicle.model'},
  {value: 'vehicle.type', label: 'vehicle.type'},
  {value: 'vehicle.vehicle', label: 'vehicle.vehicle'},
  {value: 'vehicle.vin', label: 'vehicle.vin'},
  {value: 'vehicle.vrm', label: 'vehicle.vrm'},
  {value: 'word.adjective', label: 'word.adjective'},
  {value: 'word.adverb', label: 'word.adverb'},
  {value: 'word.conjunction', label: 'word.conjunction'},
  {value: 'word.interjection', label: 'word.interjection'},
  {value: 'word.noun', label: 'word.noun'},
  {value: 'word.preposition', label: 'word.preposition'},
  {value: 'word.verb', label: 'word.verb'},
];

import Icon from './Icon';
import {Link} from 'react-router-dom';
import ProjectActions from './ProjectActions';
import ProjectLabel from './ProjectLabel';
import ProjectOwner from './ProjectOwner';
import React from 'react';
import {connect} from 'react-redux';
import {projectById} from '../reducers/projects';

const Project = ({user, project = {}, size = 'md'}) => (
  <Link
    to={`projects/${project.id}`}
    className={`
      flex items-center justify-between p-2 group transition rounded-2xl
      bg-transparent hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-200
      focus-within:bg-gray-100
    `}>
    <ProjectLabel project={project} size={size} />
    <div className="flex items-center gap-1">
      {user.id === project.owner.id ? (
        <div className="group-hover:opacity-100 group-focus:opacity-100 invisible sm:visible">
          <ProjectActions project={project} variant="minimal" />
        </div>
      ) : (
        <ProjectOwner project={project} />
      )}
      <div className="transition opacity-1 group-hover:opacity-100 ml-3 text-gray-500">
        <Icon name="ChevronRight" size={20} />
      </div>
    </div>
  </Link>
);

export default connect(({projects, user}, {id}) => ({
  project: projectById(projects, id),
  user,
}))(Project);

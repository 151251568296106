export default [
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [],
        name: 'users',
        id: '1',
      },
      {
        count: 0,
        children: [],
        name: 'posts',
        id: '4',
      },
      {
        count: 0,
        children: [],
        name: 'comments',
        id: '3',
      },
      {
        count: 0,
        children: [],
        name: 'likes',
        id: '2',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [],
            name: 'posts',
            id: '4',
          },
        ],
        name: 'users',
        id: '1',
      },
      {
        count: 0,
        children: [],
        name: 'comments',
        id: '3',
      },
      {
        count: 0,
        children: [],
        name: 'likes',
        id: '2',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [],
            name: 'posts',
            id: '4',
          },
          {
            count: 0,
            children: [],
            name: 'comments',
            id: '3',
          },
        ],
        name: 'users',
        id: '1',
      },
      {
        count: 0,
        children: [],
        name: 'likes',
        id: '2',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [],
            name: 'posts',
            id: '4',
          },
          {
            count: 0,
            children: [],
            name: 'comments',
            id: '3',
          },
          {
            count: 0,
            children: [],
            name: 'likes',
            id: '2',
          },
        ],
        name: 'users',
        id: '1',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [
              {
                count: 0,
                children: [],
                name: 'likes',
                id: '5',
              },
            ],
            name: 'posts',
            id: '4',
          },
          {
            count: 0,
            children: [],
            name: 'comments',
            id: '3',
          },
          {
            count: 0,
            children: [],
            name: 'likes',
            id: '2',
          },
        ],
        name: 'users',
        id: '1',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [
              {
                count: 0,
                children: [],
                name: 'likes',
                id: '5',
              },
              {
                count: 0,
                children: [],
                name: 'comments',
                id: '6',
              },
            ],
            name: 'posts',
            id: '4',
          },
          {
            count: 0,
            children: [],
            name: 'comments',
            id: '3',
          },
          {
            count: 0,
            children: [],
            name: 'likes',
            id: '2',
          },
        ],
        name: 'users',
        id: '1',
      },
    ],
  },
  {
    resourceTreeId: 'SECRET',
    name: 'Project X',
    prefix: '',
    id: 'fake',
    collaborators: [],
    resources: [
      {
        count: 0,
        children: [
          {
            count: 0,
            children: [
              {
                count: 0,
                children: [],
                name: 'likes',
                id: '5',
              },
              {
                count: 0,
                children: [
                  {
                    count: 0,
                    children: [],
                    name: 'likes',
                    id: '7',
                  },
                ],
                name: 'comments',
                id: '6',
              },
            ],
            name: 'posts',
            id: '4',
          },
          {
            count: 0,
            children: [],
            name: 'comments',
            id: '3',
          },
          {
            count: 0,
            children: [],
            name: 'likes',
            id: '2',
          },
        ],
        name: 'users',
        id: '1',
      },
    ],
  },
];

import {Button} from './Button';
import React from 'react';
import get from 'lodash/get';
import {getColorClassNames} from './ProjectIcon';
import {motion} from 'framer-motion';
import {useCollaborators} from '../hooks/collaborators';

export default function Collaborators({project, user, showModal}) {
  const query = useCollaborators(project);

  if (query.data.length === 0) {
    return null;
  }

  const count = Math.max(0, query.data.length - 3);
  const showCount = count > 1;
  const collaborators = showCount ? query.data.slice(0, 3) : query.data;

  return (
    <motion.div
      transition={{type: 'tween', delay: 1}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}>
      <div className="hidden sm:block">
        <Button size="md" variant="minimal" onClick={showModal}>
          <div className="flex justify-end -space-x-1.5 font-mono font-medium">
            {project.owner.id !== user.id && <Owner user={user} />}
            {collaborators.map(c => {
              const fLetter = get(c, 'email[0]', '');
              const color = getColorClassNames(fLetter);

              return (
                <div
                  key={c.id}
                  className={`flex items-center justify-center w-8 h-8 rounded-full border-2 text-white ${color}`}>
                  {c.email.slice(0, 1)}
                </div>
              );
            })}
            {showCount && (
              <div
                className="flex items-center justify-center h-8 text-gray-500
          pl-3 pr-1">
                +{count}
              </div>
            )}
          </div>
        </Button>
      </div>
    </motion.div>
  );
}

function Owner({user}) {
  const fLetter = get(user, 'name[0]', '');
  const color = getColorClassNames(fLetter);

  return (
    <div
      className={`flex items-center justify-center w-8 h-8 rounded-full border-2 text-white ${color}`}>
      {fLetter}
    </div>
  );
}

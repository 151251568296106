import React from 'react';
import {get} from 'lodash';

export function getColorClassNames(fLetter) {
  switch (fLetter) {
    case 'a':
    case 'h':
    case 'o':
    case 'v':
      return 'bg-pink-600 border-pink-300 shadow-pink-300/50';

    case 'b':
    case 'i':
    case 'p':
    case 'w':
      return 'bg-orange-600 border-orange-300 shadow-orange-300/50';

    case 'c':
    case 'j':
    case 'q':
    case 'x':
      return 'bg-emerald-500 border-emerald-300 shadow-emerald-300/50';

    case 'd':
    case 'k':
    case 'r':
    case 'y':
      return 'bg-teal-500 border-teal-300 shadow-teal-300/50';

    case 'e':
    case 'l':
    case 's':
    case 'z':
      return 'bg-indigo-500 border-indigo-300 shadow-indigo-300/50';

    case 'f':
    case 'm':
    case 't':
      return 'bg-violet-500 border-violet-300 shadow-violet-300/50';

    case 'g':
    case 'n':
    case 'u':
      return 'bg-fuchsia-500 border-fuchsia-300 shadow-fuchsia-300/50';
    default:
      return 'bg-fuchsia-500 border-fuchsia-300 shadow-fuchsia-300/50';
  }
}

const ProjectIcon = ({name}) => {
  const fLetter = get(name, '[0]', '').toLowerCase();
  const color = getColorClassNames(fLetter);
  const base = `
    flex items-center justify-center w-9 h-9 uppercase
    rounded-xl text-lg font-bold shadow-md border-2 text-white
  `;

  return <div className={`flex-shrink-0 ${base} ${color}`}>{fLetter}</div>;
};

export default ProjectIcon;

import {Button, IconButton} from './Button';
import {Transition, animated, config} from 'react-spring';

import Downshift from 'downshift';
import Icon from './Icon';
import React from 'react';
import get from 'lodash/get';

const Menu = ({orient = 'right', t, ...props}) => (
  <animated.div
    className={`absolute w-64 z-20 rounded-xl bg-gray-200 px-2 py-2 mt-1 shadow-lg border border-gray-300 ${
      orient === 'right' ? 'right-0' : 'left-0'
    }`}
    style={{
      opacity: t,
      transform: t.interpolate({
        range: [0, 1],
        output: ['translateY(-10px) scale(0.8)', 'translateY(0px) scale(1)'],
      }),
    }}
    {...props}
  />
);
const MenuTitle = props => (
  <div className="px-3 py-2 text-xs text-gray-500 font-semibold" {...props} />
);
const Separator = () => <div className=" border-t border-gray-300 my-1" />;
const Item = ({children, iconName, iconStyle, highlighted, ...rest}) => {
  let iconColor = highlighted
    ? 'text-white'
    : get(iconStyle, 'className', undefined);

  return (
    <div
      className={`cursor-pointer flex items-center whitespace-nowrap text-sm font-normal
      font-mono rounded-xl px-3 py-2 active:bg-blue-600 ${
        highlighted ? 'text-white bg-blue-500' : ''
      }`}
      {...rest}>
      {iconName && (
        <div className={`mr-3 ${iconColor}`}>
          <Icon size={14} name={iconName} />
        </div>
      )}
      {children}
    </div>
  );
};

class Dropdown extends React.Component {
  static Menu = Menu;
  static MenuTitle = MenuTitle;
  static Separator = Separator;
  static Item = Item;

  render() {
    const {
      orient,
      iconName,
      buttonText,
      children,
      tooltip,
      variant = 'secondary',
    } = this.props;
    return (
      <Downshift
        itemToString={item => (item ? item.label : '')}
        onSelect={option => option.onClick()}>
        {({getToggleButtonProps, getItemProps, isOpen, highlightedIndex}) => (
          <div className="relative z-auto font-mono">
            {iconName ? (
              <IconButton
                {...{variant, iconName, tooltip, isActive: isOpen}}
                {...getToggleButtonProps()}
              />
            ) : (
              <Button {...getToggleButtonProps()}>{buttonText}</Button>
            )}
            <Transition
              native
              config={config.wobbly}
              from={{t: 0}}
              enter={{t: 1}}
              leave={{t: 0}}>
              {isOpen &&
                (({t}) => (
                  <Menu orient={orient} t={t}>
                    {React.Children.map(children, (c, i) =>
                      c.type === Item
                        ? React.cloneElement(
                            c,
                            getItemProps({
                              key: i,
                              item: c.props,
                              onClick: e => {
                                e.stopPropagation();
                                e.preventDefault();
                              },
                              highlighted: highlightedIndex === c.props.index,
                            })
                          )
                        : c
                    )}
                  </Menu>
                ))}
            </Transition>
          </div>
        )}
      </Downshift>
    );
  }
}

export default Dropdown;

import React, {useEffect} from 'react';

import {Redirect} from 'react-router-dom';
import Splash from './Splash';
import {useAcceptInvite} from '../hooks/collaborators';

export default function AcceptInviteHandler({match: {params}}) {
  const accept = useAcceptInvite(params.id);

  useEffect(() => {
    accept.mutate(params.id, {
      onSuccess: collaborator => {
        window.location.href = `/projects/${collaborator.mock}`;
      },
    });
  }, [params.id]);

  if (accept.isError) {
    return <Redirect to={{pathname: '/projects'}} />;
  }

  return <Splash />;
}

import {Alert, Form, FormActions, Input, InputGroup} from './Form';
import {EMAIL_UPDATE, formStatus} from '../reducers/forms';
import Modal from './Modal';

import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hideForm} from '../actions/forms';
import isEmail from 'validator/lib/isEmail';
import {updateUser} from '../actions/user';

const UpdateEmialModal = ({user, form, hideForm, updateUser}) => (
  <Modal
    size="md"
    title="Update email"
    show={form.show}
    onHide={() => !form.isFetching && hideForm(EMAIL_UPDATE)}>
    <EmailForm
      user={user}
      form={form}
      updateUser={updateUser}
      onHide={() => !form.isFetching && hideForm(EMAIL_UPDATE)}
    />
  </Modal>
);

class EmailForm extends React.Component {
  state = {dirty: false, email: this.props.user.email};

  render() {
    let {user, form} = this.props;
    let {dirty, email} = this.state;
    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          this.props.updateUser({id: user.id, email});
        }}>
        {form.error && <Alert variant="danger">{form.error.message}</Alert>}
        <InputGroup label="Enter email">
          <Input
            autoFocus
            placeholder="Email"
            name="email"
            type="email"
            disabled={form.isFetching}
            value={email}
            onChange={({target}) =>
              this.setState({dirty: true, email: target.value})
            }
          />
        </InputGroup>
        <FormActions
          primary={{
            label: form.isFetching ? 'Updating...' : 'Update',
            disabled:
              !dirty ||
              !isEmail(email) ||
              email === user.email ||
              form.isFetching,
          }}
          secondary={{
            disabled: form.isFetching,
            onClick: this.props.onHide,
            label: 'Cancel',
          }}
        />
      </Form>
    );
  }
}

export default connect(
  ({user, forms}) => ({user, form: formStatus(forms, EMAIL_UPDATE)}),
  dispatch => bindActionCreators({hideForm, updateUser}, dispatch)
)(UpdateEmialModal);

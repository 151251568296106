import React from 'react';
import ProjectIcon from './ProjectIcon';

function getSize(size) {
  switch (size) {
    case 'lg':
      return 'text-lg';
    case 'md':
      return 'text-base';
    case 'sm':
      return 'text-sm';
    default:
      throw new Error(`Unknown size: ${size}`);
  }
}

const ProjectLabel = ({project, size = 'md'}) => (
  <div className={`flex items-center gap-3 font-mono ${getSize(size)}`}>
    <ProjectIcon name={project.name} />
    <div className="whitespace-nowrap truncate">
      {project.name || 'No name :('}
    </div>
  </div>
);

export default ProjectLabel;

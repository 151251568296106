import React, {Fragment, useState} from 'react';

import {Button} from '../Button';
import Card from './Card';
import EndpointsTable from '../EndpointsTable';
import PlanBreakdownTalbe from '../PlanBreakdownTable';
import ProjectPreview from './ProjectPreview';
import ResourceSchemaEditorDemo from './ResourceSchemaEditorDemo';
import Tag from '../Tag';
import {cloneDeep} from 'lodash';
import fakeProject from './fakeProject';
import {withRouter} from 'react-router-dom';

function Landing({history}) {
  const [resources, setResources] = useState(fakeProject[1].resources);
  const features = [
    {
      label: 'Pagination',
      href: 'https://github.com/mockapi-io/docs/wiki/Code-examples#pagination',
    },
    {
      label: 'Sorting',
      href: 'https://github.com/mockapi-io/docs/wiki/Code-examples#sorting',
    },
    {
      label: 'Filtering',
      href: 'https://github.com/mockapi-io/docs/wiki/Code-examples#filtering',
    },
    {
      label: 'Data generation',
      href: 'https://github.com/mockapi-io/docs/wiki/Quick-start-guide#generating-data',
    },
    {
      label: 'Customizable responses',
      href: 'https://github.com/mockapi-io/docs/wiki/Custom-responses',
    },
    {label: 'Collaboration'},
    {label: 'Custom endpoints', wip: true},
    {label: 'Webhooks', wip: true},
  ];
  const plans = [
    {plan: 'free', label: 'Free'},
    {plan: 'monthly', label: '$5', cycle: 'month'},
    {plan: 'yearly', label: '$35', cycle: 'year'},
  ];

  return (
    <div className="grid grid-cols-1 gap-32 pt-32 sm:gap-60 sm:pt-60 pb-24 font-mono">
      <div className="grid grid-cols-1 gap-10 justify-items-center text-center">
        <h1 className="text-2xl sm:text-3xl font-bold">
          The easiest way to mock REST APIs
        </h1>
        <div className="grid gap-4 justify-items-center">
          <p className="text-xl leading-relaxed">
            Quickly setup endpoints, generate custom data, and perform
            operations on it using RESTful interface
          </p>
          <div className="w-full sm:w-80 hidden md:block">
            <Button
              fullWidth
              variant="primary"
              size="lg"
              onClick={() => history.push({pathname: '/signup'})}>
              Get started
            </Button>
          </div>
          <div className="w-full sm:w-80 block md:hidden">
            <Button
              fullWidth
              variant="primary"
              size="md"
              onClick={() => history.push({pathname: '/signup'})}>
              Get started
            </Button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        {plans.map(({plan, label, cycle}) => (
          <div
            key={plan}
            className="bg-white rounded-xl shadow-xl p-6 space-y-4">
            <h1 className="text-3xl font-bold">
              <span className="oldstyle-nums">{label}</span>
              {cycle && <span className="text-lg">/{cycle}</span>}
            </h1>
            <PlanBreakdownTalbe plan={plan} />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-8 gap-6">
        <div className="col-span-3 self-center grid gap-4">
          <h2 className="text-2xl font-bold">Simple data modeling</h2>
          <p className="text-xl leading-relaxed">
            Define resource schema and data generators for each field
          </p>
        </div>
        <div className="col-span-5">
          <Card odd heading="Resource schema">
            <ResourceSchemaEditorDemo />
          </Card>
        </div>
      </div>
      <div className="grid gap-12">
        <div className="grid gap-4">
          <h1 className="text-2xl font-bold">Relations between resources</h1>
          <p className="text-xl leading-relaxed">
            Setup relations between resoures and automatically generate
            endpoints
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
          <Card heading="Resource tree">
            <ProjectPreview
              onChange={resources => {
                setResources(cloneDeep(resources));
              }}
            />
          </Card>
          <EndpointsTable resources={resources} />
        </div>
      </div>
      <div className="grid gap-6">
        <h1 className="text-2xl font-bold">And more!</h1>
        <div className="grid gap-3">
          {features.map(({label, href, wip}) => (
            <p key={label} className="text-xl leading-relaxed">
              {href ? (
                <a
                  className="flex align-middle group text-blue-700"
                  href={href}
                  target="_blank">
                  {label}
                  <span className="pl-4 opacity-0 group-hover:opacity-100">
                    #
                  </span>
                </a>
              ) : (
                <Fragment>
                  {label}
                  {wip && (
                    <span className="ml-2">
                      <Tag variant="success" label="Coming soon" />
                    </span>
                  )}
                </Fragment>
              )}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Landing);

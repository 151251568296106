import {Alert, FormActions, InputGroup} from './Form';
import {DONATE, formStatus} from '../reducers/forms';
import Modal, {Body} from './Modal';
import React, {useState} from 'react';
import {checkout, openStripeCustomerPortal} from '../actions/user';
import {hideForm, showForm} from '../actions/forms';

import Icon from './Icon';
import PlanBreakdownTable from './PlanBreakdownTable';
import Spinner from './Spinner';
import SubscriptionInfo from './SubscriptionInfo';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useSubscriptionInfo} from '../hooks/subscription';

const plans = {
  month: '$5/month',
  year: '$35/year',
};

function SubscriptionModal({user, form, ...actions}) {
  const shouldFetchSubscription =
    user.stripePlanId != null && user.stripeSubscriptionStatus !== 'canceled';
  const {status, data: subscription} = useSubscriptionInfo(
    shouldFetchSubscription
  );
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const showWarning = form.project; // hack...
  const activeSubscription = subscription && subscription.status !== 'canceled';
  const plansItems = [
    {plan: 'monthly', label: '$5', cycle: 'month'},
    {plan: 'yearly', label: '$35', cycle: 'year'},
  ];
  const closeModal = event => {
    event.preventDefault();
    if (!form.isFetching) {
      actions.hideForm(DONATE);
    }
  };

  if (status === 'pending' && shouldFetchSubscription) {
    return (
      <Modal show={form.show} onHide={closeModal} title="Manage subscription">
        <div className="flex items-center justify-center h-20">
          <Spinner />
        </div>
      </Modal>
    );
  }

  if (user.companyLicense) {
    return (
      <Modal show={form.show} onHide={closeModal} title="Manage subscription">
        <Body>
          <p className="pb-5">
            <strong>{user.companyLicense}</strong> manages your subscription 🤩
          </p>
          <FormActions
            primary={{
              variant: 'secondary',
              label: 'Close',
              onClick: closeModal,
            }}
          />
        </Body>
      </Modal>
    );
  }

  if (activeSubscription) {
    const plan = plans[subscription.interval];

    return (
      <Modal
        size="lg"
        show={form.show}
        onHide={closeModal}
        title="Manage subscription">
        <Body>
          <SubscriptionInfo subscription={subscription} plan={plan} />
          <FormActions
            primary={{
              label: form.isFetching ? 'Processing...' : 'Go to portal',
              disabled: form.isFetching,
              onClick: actions.openStripeCustomerPortal,
            }}
            secondary={{
              disabled: form.isFetching,
              onClick: closeModal,
            }}
          />
        </Body>
      </Modal>
    );
  }

  return (
    <Modal
      size="lg"
      show={form.show}
      onHide={closeModal}
      title="Manage subscription">
      <Body>
        {form.error && <Alert variant="danger">{form.error.message}</Alert>}
        {showWarning && (
          <Alert variant="warning">
            You have reached project/resource limit for a free plan.
          </Alert>
        )}
        <InputGroup label="Select a plan">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-y-auto">
            {plansItems.map(({plan, label, cycle}) => (
              <div
                onClick={() => setSelectedPlan(plan)}
                key={plan}
                className={`transition cursor-pointer rounded-xl p-6 space-y-4 border-2 ${
                  plan === selectedPlan
                    ? 'border-blue-500'
                    : 'border-white hover:bg-gray-100 active:bg-gray-200'
                }`}>
                <div className="w-6 text-blue-500">
                  <Icon
                    name="Circle"
                    className={
                      plan === selectedPlan
                        ? 'fill-blue-500'
                        : 'fill-transperent'
                    }
                  />
                </div>
                <h1 className="text-3xl ">
                  <span className="oldstyle-nums">{label}</span>
                  {cycle && <span className="text-lg">/{cycle}</span>}
                </h1>
                <PlanBreakdownTable plan={plan} />
              </div>
            ))}
          </div>
        </InputGroup>
        <FormActions
          primary={{
            label: form.isFetching
              ? 'Processing...'
              : `Continue to ${
                  selectedPlan === 'yearly' ? '$35/year' : '$5/month'
                }`,
            disabled: form.isFetching,
            onClick: () => actions.checkout(selectedPlan),
          }}
          secondary={{
            disabled: form.isFetching,
            onClick: closeModal,
          }}
        />
      </Body>
    </Modal>
  );
}

export default connect(
  ({user, forms}) => ({
    user,
    form: formStatus(forms, DONATE),
  }),
  dispatch =>
    bindActionCreators(
      {
        hideForm,
        showForm,
        openStripeCustomerPortal,
        checkout,
      },
      dispatch
    )
)(SubscriptionModal);

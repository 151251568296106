import {DELETE_RESOURCE, formStatus} from '../../reducers/forms';
import Modal, {Body} from '../Modal';
import React, {Component} from 'react';

import {Alert, FormActions} from '../Form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {deleteResource} from '../../actions/resources';
import get from 'lodash/get';
import {getResource} from '../../reducers/projectDetails';
import {hideForm} from '../../actions/forms';

class DeleteResourceModal extends Component {
  render() {
    const {form, resource} = this.props;

    return (
      <Modal
        size="md"
        show={form.show}
        onHide={this.onHide}
        title="Delete resource">
        <Body>
          {form.error && <Alert variant="danger">{form.error.message}</Alert>}
          <p className="font-mono">
            Are you sure you want to delete{' '}
            <strong>{get(resource, 'name')}</strong>?
          </p>
          <FormActions
            primary={{
              label: form.isFetching ? 'Deleting...' : 'Delete',
              variant: 'danger',
              disabled: form.isFetching,
              onClick: () =>
                this.props.deleteResource(resource.id, form.project.id),
            }}
            secondary={{
              disabled: form.isFetching,
              onClick: this.onHide,
            }}
          />
        </Body>
      </Modal>
    );
  }

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(DELETE_RESOURCE);
    }
  };
}

export default connect(
  ({forms, projectDetails}) => {
    const form = formStatus(forms, DELETE_RESOURCE);
    const resource =
      get(form, 'project.id') && form.resourceId
        ? getResource(projectDetails, form.project.id, form.resourceId)
        : null;

    return {form, resource};
  },
  dispatch => bindActionCreators({hideForm, deleteResource}, dispatch)
)(DeleteResourceModal);

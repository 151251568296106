import {Check, X} from 'react-feather';
import React from 'react';

const FEATURES = [
  {name: 'Projects', free: 1, monthly: 20, yearly: 20},
  {name: 'Resources', free: 2, monthly: 50, yearly: 50},
  // {
  //   name: 'Rate limit',
  //   free: '10 requests in 20s',
  //   monthly: '50 requests in 20s',
  //   yearly: '50 requests in 20s',
  // },
  // {
  //   name: 'Pagination',
  //   free: <X size={16} color="#e91e63" />,
  //   monthly: <Check size={16} color="#009688" />,
  //   yearly: <Check size={16} color="#009688" />,
  // },
  // {
  //   name: 'Sorting',
  //   free: <X size={16} color="#e91e63" />,
  //   monthly: <Check size={16} color="#009688" />,
  //   yearly: <Check size={16} color="#009688" />,
  // },
  // {
  //   name: 'Filtering',
  //   free: <X size={16} color="#e91e63" />,
  //   monthly: <Check size={16} color="#009688" />,
  //   yearly: <Check size={16} color="#009688" />,
  // },
  {
    name: 'Custom response',
    free: <X size={18} className="text-red-500 bg-red-50 rounded" />,
    monthly: (
      <Check size={18} className="bg-emerald-50 text-emerald-700 rounded" />
    ),
    yearly: (
      <Check size={18} className="bg-emerald-50 text-emerald-700 rounded" />
    ),
  },
  {
    name: 'Collaboration',
    free: <X size={18} className="text-red-500 bg-red-50 rounded" />,
    monthly: (
      <Check size={18} className="bg-emerald-50 text-emerald-700 rounded" />
    ),
    yearly: (
      <Check size={18} className="bg-emerald-50 text-emerald-700 rounded" />
    ),
  },
];
export default function PlanBreakdownTable({plan}) {
  return (
    <table className="table-auto w-full text-base">
      <tbody>
        {FEATURES.map(option => (
          <tr key={option.name}>
            <td className="text-gray-900">{option.name}</td>
            <td className="flex justify-center">{option[plan]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

import {AnimatePresence, motion} from 'framer-motion';

import {IconButton} from '../Button';
import Portal from '../Portal';
import React, {useEffect} from 'react';

const backdrop = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      duration: 0.2,
    },
  },
};

const modal = {
  hidden: {
    opacity: 0,
    y: '-20px',
    transition: {
      duration: '0.2',
    },
  },
  visible: {
    opacity: 1,
    y: '0px',
    transition: {
      duration: '0.3',
    },
  },
};

function getSize(size) {
  switch (size) {
    case 'sm':
      return 'max-w-sm';
    case 'md':
      return 'max-w-md';
    case 'lg':
      return 'max-w-xl';
    case 'xl':
      return 'max-w-3xl';
    default:
      throw new Error(`Invalid size: ${size}`);
  }
}

const Modal = ({title, show, onHide, children, size = 'sm'}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [show]);

  return (
    <Portal>
      <AnimatePresence>
        {show && (
          <motion.div
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`fixed z-10 top-0 bottom-0 left-0 right-0 bg-gray-900/30
          overflow-y-auto overflow-x-hidden`}>
            <motion.div
              variants={modal}
              initial="hidden"
              className={`relative mx-auto mt-14 mb-5 rounded-2xl bg-white shadow-md ${getSize(
                size
              )}`}>
              <div className="flex items-center justify-between p-4">
                <h1 className="text-xs uppercase tracking-wider font-bold text-gray-500">
                  {title}
                </h1>
                {onHide && (
                  <IconButton
                    autoFocus
                    iconName="X"
                    tooltip="Close dialog"
                    variant="minimal"
                    onClick={onHide}
                  />
                )}
              </div>
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;

import {Alert, Form, Input, InputGroup} from './Form';
import React, {useState} from 'react';

import {Button} from './Button';
import Icon from './Icon';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useResetPassword} from '../hooks/auth';

function getRequirements(password, confirmPassword) {
  return {
    length: {
      label: 'Password must be at least 7 characters',
      valid: password.length >= 7,
    },
    match: {
      label: 'Passwords must match',
      valid: password.length > 0 && password === confirmPassword,
    },
  };
}

function getErrors(requirements) {
  const keys = Object.keys(requirements);
  const errors = [];

  keys.forEach(key => {
    if (!requirements[key].valid) {
      errors.push(requirements[key].label);
    }
  });

  return errors;
}

function PasswordRequirements({requirements}) {
  const keys = Object.keys(requirements);

  return (
    <div className="space-y-2 text-xs px-2">
      {keys.map(key => (
        <div key={key} className="flex items-center justify-between gap-1">
          <p>{requirements[key].label}</p>
          <motion.div
            initial={{scale: 0.7, opacity: 0}}
            animate={{
              scale: requirements[key].valid ? 1 : 0.7,
              opacity: requirements[key].valid ? 1 : 0,
            }}
            exit={{scale: 0.7, opacity: 0}}>
            <Icon name="Check" />
          </motion.div>
        </div>
      ))}
    </div>
  );
}

export default function ChangePasswordScreen({match: {params}}) {
  const resetPassword = useResetPassword(params.token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [requirements, setRequirements] = useState(getRequirements('', ''));
  const [errors, setErrors] = useState([]);

  return (
    <motion.div initial={{opacity: 0.5}} animate={{opacity: 1}}>
      <div className="max-w-md mx-auto sm:my-20 space-y-5 font-mono">
        <h1 className="col-span-6 text-2xl font-mono font-bold text-center">
          Set new password
        </h1>
        <div className="rounded-xl bg-white shadow px-2 py-6">
          {showConfirmation ? (
            <div className="space-y-4 px-4">
              The password has been reset successfully!{' '}
              <Link to="/login" className="text-blue-700">
                Login
              </Link>
            </div>
          ) : (
            <Form
              disabled={resetPassword.isPending}
              className="grid grid-cols-1 gap-6 my-6 py-6 border-t-2 border-b-2 border-gray-200"
              onSubmit={event => {
                event.preventDefault();
                const errors = getErrors(requirements);
                if (errors.length) {
                  setErrors(errors);
                  return;
                } else {
                  setErrors([]);
                }

                resetPassword.mutate(password, {
                  onSuccess: () => {
                    setShowConfirmation(true);
                  },
                });
              }}>
              {/* API error */}
              {resetPassword.isError ? (
                <Alert variant="danger">
                  There was an error processing your request. Please try again
                  later.
                </Alert>
              ) : null}
              {/* UI validation errors */}
              {errors.length > 0 && (
                <Alert variant="danger">
                  <div className="space-y-2">
                    {errors.map(error => (
                      <div key={error}>{error}</div>
                    ))}
                  </div>
                </Alert>
              )}
              <InputGroup label="New password">
                <Input
                  required
                  placeholder="..."
                  name="password"
                  type="password"
                  disabled={resetPassword.isPending}
                  value={password}
                  onChange={event => {
                    const newPassword = event.target.value;
                    setPassword(newPassword);
                    setRequirements(
                      getRequirements(newPassword, confirmPassword)
                    );
                  }}
                />
                <PasswordRequirements requirements={requirements} />
              </InputGroup>
              <InputGroup label="Confirm password">
                <Input
                  required
                  placeholder="..."
                  name="confirm_password"
                  type="password"
                  disabled={resetPassword.isPending}
                  value={confirmPassword}
                  onChange={event => {
                    const newConfirmPassword = event.target.value;
                    setConfirmPassword(newConfirmPassword);
                    setRequirements(
                      getRequirements(password, newConfirmPassword)
                    );
                  }}
                />
              </InputGroup>
              <Button
                className="w-full"
                variant="primary"
                type="submit"
                disabled={resetPassword.isPending}
                fullWidth>
                Reset
              </Button>
            </Form>
          )}
        </div>
      </div>
    </motion.div>
  );
}

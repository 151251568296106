import {Spring, animated, config} from 'react-spring';

import Icon from './Icon';
import React from 'react';

export default function ProjectOwner({project}) {
  return (
    <Spring
      native
      config={{...config.gentle, delay: 200}}
      from={{opacity: 0}}
      to={{opacity: 1}}>
      {style => (
        <animated.div
          title={project.owner.email}
          className="hidden sm:flex items-center gap-2"
          style={style}>
          <div>
            <Icon name="Lock" />
          </div>
          <div className="text-sm text-gray-500 font-mono">
            managed by{' '}
            <span className="text-gray-950 font-bold">
              {project.owner.name || project.owner.email}
            </span>
          </div>
        </animated.div>
      )}
    </Spring>
  );
}

import React from 'react';

// Add jsdoc for status. Status can be default, primary, success, warning, danger
function getColor(variant) {
  switch (variant) {
    case 'default':
      return 'bg-gray-50 text-gray-700 border-gray-200';
    case 'primary':
      return 'bg-blue-50 text-blue-700 border-blue-200';
    case 'success':
      return 'bg-emerald-50 text-emerald-700 border-emerald-200';
    case 'warning':
      return 'bg-orange-50 text-orange-700 border-orange-200';
    case 'danger':
      return 'bg-rose-50 text-rose-700 border-rose-200';
    default:
      throw new Error(`Unknown variant: ${variant}`);
  }
}

export default function Tag({variant, label}) {
  const base = `inline-block text-xs font-mono font-semibold uppercase tracking-wider
  rounded-lg py-1 px-2 border`;
  const color = getColor(variant);

  return <div className={`${base} ${color}`}>{label}</div>;
}

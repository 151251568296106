import {Alert, Form, FormActions, Input, InputGroup} from '../Form';
import React, {Component} from 'react';
import {validFieldName, validResourceName} from '../../utils/validators';

import EndpointsEditor from './EndpointsEditor';
import ResourceSchemaEditor from './ResourceSchemaEditor';
import SubscriptionWarning from '../SubscriptionWarning';
import TemplateEditor from './TemplateEditor';
import fakerMethods from './fakerMethods';
import get from 'lodash/get';
import keys from 'lodash/keys';

const getSchemaFakerTest = () => {
  const result = [
    {
      name: 'id',
      type: 'Object ID',
    },
  ];

  for (const item of fakerMethods) {
    result.push({
      name: item.label.replace(/\./g, '_').toLowerCase(),
      type: 'Faker.js',
      fakerMethod: item.value,
    });
  }

  return result;
};

const getSchema = () => [
  {
    name: 'id',
    type: 'Object ID',
  },
  {
    name: 'createdAt',
    type: 'Faker.js',
    fakerMethod: 'date.recent',
  },
  {
    name: 'name',
    type: 'Faker.js',
    fakerMethod: 'name.findName',
  },
  {
    name: 'avatar',
    type: 'Faker.js',
    fakerMethod: 'image.avatar',
  },
];

const getEndpoints = name => {
  if (!name || !name.length) {
    name = '...';
  }

  return [
    {
      enabled: true,
      method: 'GET',
      url: '/' + name,
      response: '$mockData',
    },
    {
      enabled: true,
      method: 'GET',
      url: '/' + name + '/:id',
      response: '$mockData',
    },
    {
      enabled: true,
      method: 'POST',
      url: '/' + name,
      response: '$mockData',
    },
    {
      enabled: true,
      method: 'PUT',
      url: '/' + name + '/:id',
      response: '$mockData',
    },
    {
      enabled: true,
      method: 'DELETE',
      url: '/' + name + '/:id',
      response: '$mockData',
    },
  ];
};

const getInitialState = resource => ({
  changed: false,
  name: get(resource, 'name', ''),
  generator: get(resource, 'generator', ''),
  schema: get(resource, 'resourceSchema', getSchema()),
  endpoints: get(resource, 'endpoints', getEndpoints(get(resource, 'name'))),
  errors: {
    name: null,
    generator: null,
    endpoints: {},
    schema: {},
  },
});

class ResourceForm extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props.resource);
  }

  render() {
    const {edit, form, disabled, isActiveSub} = this.props;
    const resource = this.state;
    const disableSubmitBtn =
      disabled ||
      form.isFetching ||
      form.error ||
      !resource.name ||
      !resource.changed ||
      this.hasErrors();

    let submitBtnText = '';

    if (edit) {
      submitBtnText = form.isFetching ? 'Updating...' : 'Update';
    } else {
      submitBtnText = form.isFetching ? 'Creating...' : 'Create';
    }

    return (
      <Form onSubmit={this.onSubmit}>
        {disabled && <SubscriptionWarning />}
        {form.error && <Alert variant="danger">{form.error.message}</Alert>}
        {!edit && (
          <InputGroup
            label="Resource name"
            helpText="Enter meaningful resource name, it will be used to generate API endpoints.">
            <Input
              autoFocus
              placeholder="Example: users, comments, articles..."
              name="name"
              type="text"
              disabled={form.isFetching}
              value={resource.name}
              onChange={this.onNameChange}
              errorMessage={
                resource.errors.name && resource.errors.name.message
              }
            />
          </InputGroup>
        )}
        <InputGroup
          label="Schema"
          helpText="Define Resource schema, it will be used to generate mock data.">
          <ResourceSchemaEditor
            errors={resource.errors.schema}
            schema={resource.schema}
            childResources={get(this.props, 'resource.children', [])}
            parentResource={this.props.parentResource}
            onSchemaItemChange={this.onSchemaItemChange}
            onSchemaError={this.onSchemaError}
            addSchemaItem={this.addSchemaItem}
            deleteSchemaItem={this.deleteSchemaItem}
          />
        </InputGroup>
        <InputGroup
          label="Object template"
          unavailable={!isActiveSub}
          helpText={
            <span>
              To define more complex structure for your data, use JSON template.
              You can reference Faker.js function using{' '}
              <span className="font-semibold rounded px-1 bg-gray-200">
                $function_name
              </span>
              .
            </span>
          }>
          <TemplateEditor
            isActiveSub={isActiveSub}
            value={resource.generator}
            error={resource.errors.generator}
            onChange={this.onTemplateChange}
            onError={this.onTemplateError}
          />
        </InputGroup>
        <InputGroup label="Endpoints" unavailable={!isActiveSub}>
          <div className="space-y-2 font-mono">
            <p className="text-sm">
              Enable/disable endpoints and customize response JSON.
            </p>
            <p className="text-sm">
              By default, Mockapi will return either a list of items or a single
              item, depending on the request. To define more complex response,
              provide JSON template. You can reference Faker.js methods using{' '}
              <span className="font-semibold rounded px-1 bg-gray-200">
                $function_name
              </span>
              .
            </p>
            <ul className="list-disc pl-4 text-sm">
              <li>
                <span className="font-semibold rounded px-1 bg-gray-200">
                  $mockData
                </span>{' '}
                - data stored in DB.
              </li>
              <li>
                <span className="font-semibold rounded px-1 bg-gray-200">
                  $count
                </span>{' '}
                - number of records stored in DB.
              </li>
            </ul>
            <EndpointsEditor
              isActiveSub={isActiveSub}
              errors={resource.errors.endpoints}
              endpoints={resource.endpoints}
              onEndpointChange={this.onEndpointChange}
              onEndpointError={this.onEndpointError}
            />
          </div>
        </InputGroup>
        <FormActions
          primary={{
            label: submitBtnText,
            disabled: disableSubmitBtn,
          }}
          secondary={{
            disabled: form.isFetching,
            onClick: this.props.onHide,
          }}
        />
      </Form>
    );
  }

  onNameChange = ({target: {name, value}}) => {
    if (validResourceName(value)) {
      this.setState({
        [name]: value,
        changed: true,
        endpoints: getEndpoints(value),
        errors: {
          ...this.state.errors,
          name: null,
        },
      });
      this.props.clearFormError();
    }
  };

  addSchemaItem = () =>
    this.setState({
      schema: [
        ...this.state.schema,
        {name: '', type: 'Faker.js', fakerMethod: 'random.word'},
      ],
    });

  deleteSchemaItem = i => {
    const {schema} = this.state;
    const updatedSchema = [...schema.slice(0, i), ...schema.slice(i + 1)];
    this.setState({
      schema: updatedSchema,
      changed: true,
      errors: {...this.state.errors, schema: {}},
    });
    this.props.clearFormError();
  };

  onSchemaItemChange = (i, newItem) => {
    const {schema, errors} = this.state;
    const updatedSchema = [
      ...schema.slice(0, i),
      newItem,
      ...schema.slice(i + 1),
    ];

    const {[String(i)]: removed, ...rest} = errors.schema;

    this.setState({
      schema: updatedSchema,
      changed: true,
      errors: {
        ...this.state.errors,
        schema: rest,
      },
    });
    this.props.clearFormError();
  };

  onSchemaError = (i, error) => {
    this.setState({
      errors: {
        ...this.state.errors,
        schema: {
          ...this.state.errors.schema,
          [String(i)]: error,
        },
      },
    });
  };

  onEndpointChange = (i, newEndpoint) => {
    const {endpoints, errors} = this.state;
    const updatedEndpoints = [
      ...endpoints.slice(0, i),
      newEndpoint,
      ...endpoints.slice(i + 1),
    ];

    const {[String(i)]: removed, ...rest} = errors.endpoints;

    this.setState({
      endpoints: updatedEndpoints,
      changed: true,
      errors: {
        ...this.state.errors,
        endpoints: rest,
      },
    });
    this.props.clearFormError();
  };

  onEndpointError = (i, error) => {
    this.setState({
      errors: {
        ...this.state.errors,
        endpoints: {
          ...this.state.errors.endpoints,
          [String(i)]: error,
        },
      },
    });
  };

  onTemplateChange = generator => {
    this.setState({
      generator,
      changed: true,
      errors: {
        ...this.state.errors,
        generator: null,
      },
    });
    this.props.clearFormError();
  };

  onTemplateError = error => {
    this.setState({
      errors: {
        ...this.state.errors,
        generator: error,
      },
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const {form, resource, disabled} = this.props;
    const {name, generator, schema, endpoints} = this.state;
    let nameError = null;
    let generatorError = null;
    let schemaErrors = {};
    let endpointsErrors = {};

    if (disabled) return;

    if (!name || !name.trim() || !validResourceName(name)) {
      nameError = new Error('Invalid resource name');
    }

    if (generator) {
      try {
        JSON.parse(generator);
      } catch (error) {
        generatorError = new Error('Invalid JSON');
      }
    }

    schema.forEach((item, i) => {
      if (!item.name || !validFieldName(item.name)) {
        schemaErrors = {
          ...schemaErrors,
          [String(i)]: new Error('Invalid field name'),
        };
      }
    });

    endpoints.forEach((item, i) => {
      if (!item.response) {
        endpointsErrors = {
          ...endpointsErrors,
          [String(i)]: new Error('Invalid response, must be $mockData or JSON'),
        };
      }
    });

    if (
      !nameError &&
      !generatorError &&
      !keys(schemaErrors).length &&
      !keys(endpointsErrors).length
    ) {
      this.props.onSubmit(
        {
          id: get(resource, 'id'),
          name,
          generator,
          schema,
          endpoints,
        },
        form.project.id
      );
    }

    this.setState({
      ...this.state,
      errors: {
        name: nameError,
        schema: schemaErrors,
        endpoints: endpointsErrors,
        generator: generatorError,
      },
    });
  };

  hasErrors = () => {
    const {errors} = this.state;
    return (
      errors.name ||
      errors.generator ||
      keys(errors.schema).length ||
      keys(errors.endpoints).length
    );
  };
}

export default ResourceForm;

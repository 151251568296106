import {EDIT_PROJECT, formStatus} from '../../reducers/forms';
import Modal from '../Modal';
import React, {Component} from 'react';
import {clearFormError, hideForm} from '../../actions/forms';

import ProjectForm from './ProjectForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hasActiveSub} from '../../utils/user';
import {updateProject} from '../../actions/projects';

class EditProjectModal extends Component {
  render() {
    const {form, isActiveSub} = this.props;

    return (
      <Modal
        size="md"
        show={form.show}
        onHide={this.onHide}
        title="Edit project">
        <ProjectForm
          edit
          form={form}
          isActiveSub={isActiveSub}
          project={form.project}
          onHide={this.onHide}
          onSubmit={this.props.updateProject}
          clearFormError={this.clearFormError}
        />
      </Modal>
    );
  }

  clearFormError = () => {
    this.props.clearFormError(EDIT_PROJECT);
  };

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(EDIT_PROJECT);
    }
  };
}

export default connect(
  ({forms, user}) => ({
    form: formStatus(forms, EDIT_PROJECT),
    isActiveSub: hasActiveSub(user),
  }),
  dispatch =>
    bindActionCreators({hideForm, clearFormError, updateProject}, dispatch)
)(EditProjectModal);

import App from './App';
import {Provider} from 'react-redux';
import React from 'react';
import {createRoot} from 'react-dom/client';
import store from './store';

// Redirect to https
if (
  window.location.hostname !== 'localhost' &&
  window.location.protocol !== 'https:'
) {
  window.location.href = window.location.href.replace('http:', 'https:');
}

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

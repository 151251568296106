import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fetchProjects} from '../actions/projects';
import {showForm} from '../actions/forms';
import {notify} from '../actions/notifications';
import {DONATE, NEW_PROJECT, formStatus} from '../reducers/forms';
import {Transition, animated} from 'react-spring';
import Project from './Project';
import Nodata from './Nodata';

const Projects = ({ids}) => (
  <div className="bg-white rounded-3xl p-4 shadow -mx-4 sm:mx-auto">
    <Transition
      native
      items={ids}
      keys={ids}
      initial={{height: 52, opacity: 1}}
      from={{height: 0, opacity: 0}}
      enter={{height: 52, opacity: 1}}
      leave={{height: 0, opacity: 0}}>
      {ids.map(id => styles => (
        <animated.div style={{...styles, position: 'relative'}}>
          <Project id={id} size="lg" />
        </animated.div>
      ))}
    </Transition>
  </div>
);

class ProjectList extends Component {
  componentDidMount() {
    this.props.fetchProjects();

    const params = new URLSearchParams(window.location.search);

    switch (params.get('checkout')) {
      case 'success':
        setTimeout(() => this.props.showForm(DONATE), 200);
        break;
      case 'failure':
        this.props.notify({
          type: 'failure',
          message: `There was an error processing your request.`,
        });
        break;
      default:
        // do nothing
        break;
    }
  }

  render() {
    const {isFetching, ids} = this.props;
    let items = [];

    if (ids.length) {
      items = [{key: 'list', component: Projects, props: {ids}}];
    } else if (!isFetching && !ids.length) {
      items = [
        {
          key: 'nodata',
          component: Nodata,
          props: {
            message: 'No projects yet...',
          },
        },
      ];
    }

    return (
      <Transition
        native
        items={items}
        keys={({key}) => key}
        from={{opacity: 0.1, y: 15}}
        enter={{opacity: 1, y: 0}}
        leave={{
          opacity: 0,
          y: -15,
          position: 'absolute',
          top: 70,
          left: 0,
          right: 0,
        }}>
        {items.map(item => ({y, ...styles}) => (
          <animated.div
            style={{
              ...styles,
              transform: y.interpolate(y => `translateY(${y}px)`),
            }}>
            <item.component {...item.props} />
          </animated.div>
        ))}
      </Transition>
    );
  }

  componentWillReceiveProps({isFetching, ids, prompt}) {
    if (!isFetching && !ids.length && prompt) {
      setTimeout(() => this.props.showForm(NEW_PROJECT), 500);
    }
  }
}

export default connect(
  ({projects, forms}) => ({
    isFetching: projects.isFetching,
    error: projects.error,
    ids: projects.ids,
    prompt: formStatus(forms, NEW_PROJECT).prompt,
  }),
  dispatch => bindActionCreators({fetchProjects, showForm, notify}, dispatch)
)(ProjectList);

import {DELETE_PROJECT, formStatus} from '../../reducers/forms';
import Modal, {Body} from '../Modal';
import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';

import {Alert, FormActions} from '../Form';
import {connect} from 'react-redux';
import {deleteProject} from '../../actions/projects';
import get from 'lodash/get';
import {hideForm} from '../../actions/forms';
import {withRouter} from 'react-router';

class DeleteProjectModal extends Component {
  render() {
    const {form, history} = this.props;

    return (
      <Modal
        size="md"
        show={form.show}
        onHide={this.onHide}
        title="Delete project">
        <Body>
          {form.error && <Alert variant="danger">{form.error.message}</Alert>}
          <p className="font-mono">
            Are you sure you want to delete{' '}
            <strong>{get(form, 'project.name')}</strong>?
          </p>
          <FormActions
            primary={{
              disabled: form.isFetching,
              variant: 'danger',
              onClick: () =>
                this.props.deleteProject(form.project.id, () => {
                  form.redirect && history.push('/projects');
                }),
              label: form.isFetching ? 'Deleting...' : 'Delete',
            }}
            secondary={{
              disabled: form.isFetching,
              onClick: this.onHide,
            }}
          />
        </Body>
      </Modal>
    );
  }

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(DELETE_PROJECT);
    }
  };
}

export default compose(
  withRouter,
  connect(
    ({forms}) => ({form: formStatus(forms, DELETE_PROJECT)}),
    dispatch => bindActionCreators({hideForm, deleteProject}, dispatch)
  )
)(DeleteProjectModal);

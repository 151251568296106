import Modal from '../Modal';
import {NEW_PROJECT, formStatus} from '../../reducers/forms';
import React, {Component} from 'react';
import {clearFormError, hideForm} from '../../actions/forms';

import ProjectForm from './ProjectForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createProject} from '../../actions/projects';
import {hasActiveSub} from '../../utils/user';

class NewProjectModal extends Component {
  render() {
    const {form, isActiveSub} = this.props;

    return (
      <Modal
        size="md"
        show={form.show}
        onHide={this.onHide}
        title="New project">
        <ProjectForm
          isActiveSub={isActiveSub}
          form={form}
          onHide={this.onHide}
          onSubmit={this.props.createProject}
          clearFormError={this.clearFormError}
        />
      </Modal>
    );
  }

  clearFormError = () => {
    this.props.clearFormError(NEW_PROJECT);
  };

  onHide = event => {
    event.preventDefault();
    if (!this.props.form.isFetching) {
      this.props.hideForm(NEW_PROJECT);
    }
  };
}

export default connect(
  ({forms, user}) => ({
    form: formStatus(forms, NEW_PROJECT),
    isActiveSub: hasActiveSub(user),
  }),
  dispatch =>
    bindActionCreators({hideForm, clearFormError, createProject}, dispatch)
)(NewProjectModal);

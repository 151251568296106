import {Alert} from './Form';
import {Button} from './Button';
import {DONATE} from '../reducers/forms';
import React from 'react';
import {connect} from 'react-redux';
import {showForm} from '../actions/forms';

const SubscriptionWarning = props => (
  <Alert variant="warning">
    Looks like you don't have a subscription.
    <br />
    <Button
      onClick={() => {
        props.showForm(DONATE);
      }}
      style={{
        fontSize: 12,
        height: 27,
        padding: '0 12px',
        marginTop: 10,
      }}>
      Subscribe
    </Button>
  </Alert>
);

export default connect(null, {showForm})(SubscriptionWarning);
